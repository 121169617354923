import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useUserSelector } from './useUserSelector';


export const useReferralHandler = () => {
  const user = useUserSelector();
  const {search} = useLocation();
  
  useEffect(() => {
    if (user) {
      return;
    }

    const qs = new URLSearchParams(search)

    if (qs.get("referral")) {
      localStorage.setItem('referralId', qs.get("referral"));
    }
  }, []);

  const clearReferral = () => {
    localStorage.removeItem('referralId');
  };

  const getReferral = () => localStorage.getItem('referralId') || '';

  const handleReferral = (code) => {
    if (user) {
      return;
    }

    localStorage.setItem('referralId', code);
  };

  return {
    clearReferral,
    getReferral,
    handleReferral,
  };
};
