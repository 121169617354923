import { motion } from 'framer-motion';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ReactSwitch from 'react-switch';
import { shouldUseDsoStructure } from '~/growthbook';
import Button from '../../commonComponents/Button';
import { Colors } from '../../themes/colors';
import { getPopupValues } from './utils';

export function AcceptTermsPopup() {
  const dispatch = useDispatch();
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const user = useSelector((state) => state.user.selectedChildOffice ?? state.user.user);
  const gotuTermsV2 = useSelector((state) => state.user.gotuTerms);
  const gotuTermsAccepted = shouldUseDsoStructure()
    ? gotuTermsV2?.accepted
    : user?.office_info?.gotuTerms?.accepted;
  const popupValues = getPopupValues(user, gotuTermsAccepted);

  const handleButtonClick = () => {
    popupValues.onButtonClick.forEach((callback) => dispatch(callback({ officeId: user?.id })));
  };

  if (!popupValues || (gotuTermsAccepted && user?.office_info?.psaTerms?.accepted)) {
    return <></>;
  }

  return (
    <button type="button" style={{ all: 'unset' }}>
      <div
        className="modal"
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          position: 'fixed',
        }}
      >
        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.4 }}
          style={{
            width: '28%',
            maxWidth: 700,
            maxHeight: '90%',
            backgroundColor: Colors.white,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            borderRadius: 14,
            padding: 35,
            paddingBottom: 48,
            overflowY: 'auto',
          }}
          onClick={(event) => event.stopPropagation()}
        >
          {popupValues.image && (
            <img
              src={popupValues.image}
              style={{ width: 220, marginBottom: 16 }}
              alt="welcome_illustration"
            />
          )}

          <h1
            style={{
              fontFamily: 'Nunito',
              fontWeight: 'bold',
              fontSize: 18,
              color: Colors.neutral_600,
            }}
          >
            {popupValues.title}
          </h1>
          <p
            style={{
              fontFamily: 'Nunito',
              fontSize: 16,
              color: Colors.neutral_500,
              margin: 0,
              marginBottom: 32,
              textAlign: 'center',
            }}
          >
            {popupValues.description}
          </p>

          {popupValues.switchText && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: 32,
                gap: 16,
              }}
            >
              <ReactSwitch
                checked={acceptedTerms}
                onChange={() => setAcceptedTerms(!acceptedTerms)}
                onColor={Colors.secondary_500}
                onHandleColor="#fff"
                handleDiameter={20}
                uncheckedIcon={false}
                checkedIcon={false}
                offColor={Colors.neutral_100}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.35)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={25}
                width={50}
                className="react-switch"
                id="material-switch"
              />
              <p className="global_font" style={{ margin: 0, color: Colors.neutral_500 }}>
                {popupValues.switchText}
              </p>
            </div>
          )}

          <Button
            type="button"
            style={{
              width: 150,
            }}
            disabled={popupValues.switchText && !acceptedTerms}
            onClick={handleButtonClick}
            text={popupValues.buttonText}
          />
        </motion.div>
      </div>
    </button>
  );
}
