import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Center, Column, Text } from '../../../commonComponents';
import { UserProfession } from '../../../enums/UserProfession';
import {
  EmptyFilteredFavorites,
  EmptyFilteredHighlyRated,
  EmptyFilteredRecent,
} from '../../../images/professionalHub';
import { Colors } from '../../../themes/colors';
import { TABS } from '../utils';

export const EmptyFilteredList = () => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 0.5 }}
    style={{
      maxWidth: '827px',
      display: 'flex',
      flexDirection: 'column',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: 40,
      marginBottom: 80,
    }}
  >
    <Description />
  </motion.div>
);

const Description = () => {
  const filter = useSelector((state) => state.user.professionalHubFilter);
  const params = useParams();
  const { type } = params;

  let title = '';
  let description = '';
  let Icon = null;

  switch (type) {
    case TABS.FAVORITES_LIST:
      Icon = EmptyFilteredFavorites;
      title = 'No professionals added yet!';
      description = `You have not added any ${UserProfession.getName(
        filter,
      )} to your Favorites List yet. Add one to get started!`;
      break;
    case TABS.HIGHLY_RATED:
      Icon = EmptyFilteredHighlyRated;
      title = 'No professionals rated yet!';
      description = `Your office has not rated any ${UserProfession.getName(
        filter,
      )} highly on GoTu yet. Check back after giving some feedback!`;
      break;
    case TABS.RECENT_PROFESSIONALS:
      Icon = EmptyFilteredRecent;
      title = 'No professionals yet!';
      description = `Your office has not recently worked with any ${UserProfession.getName(
        filter,
      )} on GoTu yet. Connect with one to get started!`;
      break;
    default:
  }

  return (
    <Center width={400} gap={32}>
      {Icon && <Icon />}
      <Column gap={8}>
        <Text color={Colors.neutral_500} bold fontSize={22} textAlign="center">
          {title}
        </Text>
        <Text color={Colors.neutral_500} textAlign="center">
          {description}
        </Text>
      </Column>
    </Center>
  );
};
