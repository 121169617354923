import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { showMessage } from '../actions';
import { trackEvent } from '../api/analytics';
import history from '../history';
import logoLightFull from '../images/logos/logoLightFull.svg';
import { Colors } from '../themes/colors';
import '../themes/navigation.scss';
import { useInboxCount } from './Inbox/hooks/useInboxCount';

const MenuTitle = ({ title, isActive }) => (
  <p
    style={{
      fontFamily: 'Nunito',
      fontSize: 18,
      color: Colors.white,
      ...(isActive && {
        fontWeight: 700,
        color: Colors.success_400,
      }),
    }}
  >
    {title}
  </p>
);

export default function TopNavBar() {
  const location = useLocation();
  const dispatch = useDispatch();

  const { formattedTotalInbox, totalInbox } = useInboxCount();

  const getHelp = () => {
    if (location.pathname === '/registration') {
      dispatch(
        showMessage({
          title: 'Error',
          message: 'Please finish your registration to access the rest of the platform.',
          isError: true,
        }),
      );
    } else {
      history.push('/dashboard/help');
    }
  };

  const goToInbox = () => {
    if (location.pathname === '/registration') {
      dispatch(
        showMessage({
          title: 'Error',
          message: 'Please finish your registration to access the rest of the platform.',
          isError: true,
        }),
      );
    } else {
      trackEvent('Office Inbox Tab Clicked', {
        amount: totalInbox,
      });
      history.push('/dashboard/inbox');
    }
  };

  const goToAccount = () => {
    trackEvent('Office Account Tab Clicked');
    history.push('/account');
  };

  const getBadgeCount = () => {
    const isTwoDigits = totalInbox > 9;

    if (totalInbox > 0) {
      return (
        <div
          style={{
            borderRadius: 25,
            backgroundColor: Colors.error_500,
            height: 20,
            minWidth: 20,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingLeft: isTwoDigits ? 4 : 0,
            paddingRight: isTwoDigits ? 4 : 0,
          }}
        >
          <span
            style={{
              fontSize: 14,
              fontFamily: 'Nunito',
              fontWeight: 'bold',
              color: 'white',
            }}
          >
            {formattedTotalInbox}
          </span>
        </div>
      );
    }
    return <div />;
  };

  return (
    <div className="container">
      <div className="nav_container" style={{ backgroundColor: Colors.primary_500 }}>
        <img
          src={logoLightFull}
          alt="logo_light_full"
          className="logo"
          style={{ cursor: 'pointer', width: 95, marginLeft: 30 }}
          onClick={() => history.push('/')}
        />
        {!location.pathname.includes('registration') ? (
          <div
            style={{
              display: 'flex',
              gap: 36,
              marginRight: 36,
            }}
          >
            <div style={{ cursor: 'pointer' }} onClick={() => goToInbox()}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 4,
                }}
              >
                <MenuTitle title="Inbox" isActive={location.pathname === '/dashboard/inbox'} />
                <div>{getBadgeCount()}</div>
              </div>
            </div>
            <div style={{ cursor: 'pointer' }} onClick={() => getHelp()}>
              <MenuTitle title="Help" isActive={location.pathname === '/dashboard/help'} />
            </div>
            <div style={{ cursor: 'pointer' }} onClick={() => goToAccount()}>
              <MenuTitle title="Account" isActive={location.pathname === '/account'} />
            </div>
          </div>
        ) : (
          <div />
        )}
      </div>
    </div>
  );
}
