import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BULK_CONFIRM_JOBS, bulkConfirmJobs, fetchProfessionalJobs } from '~/actions';
import { createLoadingSelector } from '~/api/selectors';
import browserHistory from '~/history';
import { useIsSuccess } from '~/hooks';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useUserSelector } from '~/hooks/useUserSelector';
import { IJob, IMultipleCounters, WorkerClassificationTypeEnum } from '~/models';
import { TRootState } from '~/store/types';

const confirmJobsLoading = createLoadingSelector(['BULK_CONFIRM_JOBS']);

export const useBulkConfirmCandidates = (candidateId: string) => {
  const dispatch = useDispatch();

  const [checkedJobs, setCheckedJobs] = useState<Record<string, boolean>>({});

  const { notes_for_temp, workerClassification } = useUserSelector();

  const {
    isActiveRequest,
    jobs,
    pagination: { isFinal, page },
  } = useAppSelector((state: TRootState) => state.job.candidateJobs);
  const hasMoreJobs = useAppSelector(
    (state: TRootState) =>
      state.job.actionRequiredJobs.find((job: IJob) =>
        job.multipleCounters.find(
          (counter: IMultipleCounters) => counter.candidate.id === candidateId,
        ),
      ) !== undefined,
  );

  const isContractor =
    workerClassification?.classificationType === WorkerClassificationTypeEnum.Contractor;
  const isEmployee =
    workerClassification?.classificationType === WorkerClassificationTypeEnum.Employee;

  const isLoadingJobsConfirmation = useAppSelector((state: TRootState) =>
    confirmJobsLoading(state),
  );
  const { clearSuccess, isSuccess: isJobsConfirmed } = useIsSuccess([BULK_CONFIRM_JOBS]);

  const getJobs = useCallback(
    ({ limit, page }: Partial<{ limit: number; page: number }>) => {
      dispatch(
        fetchProfessionalJobs({
          limit: limit || 15,
          page: page || 1,
          professionalId: candidateId,
          status: 'pending-counter',
        }),
      );
    },
    [candidateId, dispatch],
  );

  const handleClosePopup = useCallback(() => {
    clearSuccess();

    if (!isJobsConfirmed) return;

    if (isEmployee && hasMoreJobs) return;

    browserHistory.push('/dashboard/jobs/pending');
  }, [clearSuccess, hasMoreJobs, isEmployee, isJobsConfirmed]);

  const handleConfirm = useCallback(() => {
    const payload = Object.keys(checkedJobs).map((jobId) => {
      const job: IJob = jobs.find((j: IJob) => j.id === jobId);
      const offerCounter = job.multipleCounters?.[0];

      return {
        counterCoverId: offerCounter.id,
        jobId: job.id,
        notes: notes_for_temp,
        professionalId: candidateId,
      };
    });

    dispatch(bulkConfirmJobs(payload));
  }, [candidateId, checkedJobs, dispatch, jobs, notes_for_temp]);

  const handleToggleOption = useCallback(
    (jobId?: string) => {
      setCheckedJobs((prev) => {
        if (isContractor) {
          if (!jobId) {
            return Object.keys(prev).length === jobs.length
              ? {}
              : jobs.reduce((acc: Record<string, boolean>, job: IJob) => {
                  acc[job.id] = true;
                  return acc;
                }, {});
          }

          const newCheckedJobs = { ...prev };
          if (newCheckedJobs[jobId]) {
            delete newCheckedJobs[jobId];
          } else {
            newCheckedJobs[jobId] = true;
          }

          return newCheckedJobs;
        }

        return { [jobId!]: true };
      });
    },
    [isContractor, jobs],
  );

  const handleScroll = useCallback(() => {
    const { scrollTop, scrollHeight, clientHeight } = document.documentElement;

    if (isActiveRequest || isFinal) {
      return;
    }

    if (scrollHeight - scrollTop > clientHeight + 100) {
      return;
    }

    getJobs({ page: page + 1 });
  }, [getJobs, isActiveRequest, isFinal, page]);

  useEffect(() => {
    if (!candidateId) {
      return;
    }

    getJobs({ page: 1 });
  }, []);

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return {
    checkedJobs,
    isContractor,
    isEmployee,
    isFirstLoading: isActiveRequest && page === 1,
    isGetJobsLoading: isActiveRequest,
    isLoadingJobsConfirmation,
    isJobsConfirmed: isJobsConfirmed && !isLoadingJobsConfirmation,
    jobs,
    handleClosePopup,
    handleConfirm,
    handleToggleOption,
  };
};
