import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearSuccess as clearSuccessAction } from '~/actions/success';
import { createSuccessSelector } from '~/api/selectors';

export default function useIsSuccess(actions: string[] = []) {
  const dispatch = useDispatch();
  const successSelector = createSuccessSelector(actions);

  const clearSuccess = useCallback(() => {
    dispatch(clearSuccessAction(actions));
  }, [dispatch, actions]);

  return {
    selector: successSelector,
    clearSuccess,
    isSuccess: useSelector((state) => successSelector(state)),
  };
}
