import { Box, Row, Text } from '~/commonComponents';

// @ts-expect-error - unable to resolve path
import CopyIcon from '~/images/copy-icon.svg';

interface IReferralLinkProps {
  link: string;
  style?: React.CSSProperties;
}

export const ReferralLink = ({ link, style }: IReferralLinkProps) => {
  return (
    <Box style={style}>
      <Text bold color="primary_500">
        Copy and Share Your Link
      </Text>
      <Row gap={20} paddingTop={16}>
        <input
          className="input-field"
          style={{ margin: 0, width: '380px', borderRadius: 10 }}
          type="text"
          value={link}
          disabled={true}
        />

        <button
          type="button"
          className="button"
          style={{
            backgroundColor: 'inherit',
            alignItems: 'center',
            display: 'flex',
            alignSelf: 'center',
            margin: 0,
            paddingBlock: 3,
          }}
          onClick={() => {
            navigator.clipboard.writeText(link);
          }}
        >
          <img src={CopyIcon} style={{ width: 17, height: 20, marginRight: 5 }} alt="icon" />
          <Text bold fontSize={18} color="secondary_500">
            Copy
          </Text>
        </button>
      </Row>
    </Box>
  );
};
