import { useEffect, useRef, useState } from 'react';

import { useSelector } from 'react-redux';
import { Siderbar } from './Sidebar';
import { FAQ } from './sections/FAQ';
import { Overview } from './sections/Overview';
import { WorkerClassificationSelection } from './sections/WorkerClassificationSelection';

const useReferences = () => {
  const referencesByKey = useRef({});

  const setReference = (element, key) => {
    referencesByKey.current[key] = element;
  };

  return { refsByKey: referencesByKey.current, setRef: setReference };
};

export const Content = ({ isRegistration }) => {
  const { refsByKey, setRef } = useReferences();
  const user = useSelector((state) => state.user);
  const { selectedChildOffice } = user;
  const userClassification = selectedChildOffice?.workerClassification?.classificationType;
  const latestWorkerClassificationRequest = selectedChildOffice?.latestWorkerClassificationRequest;
  const workerClassificationRequest = user?.workerClassificationRequest;
  const [activeSection, setActiveSection] = useState(null);
  const isPendingWCRequest =
    latestWorkerClassificationRequest?.status === 'requested' ||
    (workerClassificationRequest?.length === 1 &&
      workerClassificationRequest?.[0]?.status === 'requested');

  const handleScroll = () => {
    const sectionKeys = Object.keys(refsByKey);
    let activeSectionKey = null;
    const maxScroll = document.body.scrollHeight - window.innerHeight;

    sectionKeys.forEach((key, index) => {
      const currentRef = refsByKey[key];

      const nextRef = refsByKey[sectionKeys[index + 1]];

      const currentTop = currentRef ? currentRef.offsetTop - 200 : 0;

      const nextTop = nextRef ? nextRef.offsetTop : Number.POSITIVE_INFINITY;

      const scrollPosition = window.scrollY;

      if (scrollPosition >= currentTop && scrollPosition < nextTop) {
        activeSectionKey = key;
      }

      if (scrollPosition >= maxScroll - 100) {
        activeSectionKey = sectionKeys[sectionKeys.length - 1];
      }
    });
    setActiveSection(activeSectionKey);
  };

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const debouncedHandleScroll = debounce(handleScroll, 50);

  useEffect(() => {
    window.addEventListener('scroll', debouncedHandleScroll);
    return () => {
      window.removeEventListener('scroll', debouncedHandleScroll);
    };
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        marginLeft: 20,
        height: '100%',
        ...(isRegistration && { marginLeft: 0 }),
      }}
    >
      <Siderbar
        isRegistration={isRegistration}
        refsByKey={refsByKey}
        activeSection={activeSection}
      />

      <div
        style={{
          width: 'calc(100% - 400px)',
          marginLeft: 80,
          marginRight: 50,
          marginBottom: 120,
        }}
      >
        <div ref={(element) => setRef(element, 'overview')}>
          <Overview />
        </div>
        <div ref={(element) => setRef(element, 'faqs')}>
          <FAQ isRegistration={isRegistration} showDescription={!userClassification} />
        </div>

        {!isPendingWCRequest && !userClassification && (
          <div ref={(element) => setRef(element, 'worker-classification-selection')}>
            <WorkerClassificationSelection isRegistration={isRegistration} />
          </div>
        )}
      </div>
    </div>
  );
};
