import { RouteObject, useRoutes } from 'react-router';
import { PageAnalytics } from '../PageAnalytics';

type TRouteObject = RouteObject & {
  title?: string;
  path: string;
  children?: TRouteObject[];
};

export const useAppRoutes = (routesObj: TRouteObject[]) => {
  const processRoutes = (routes: TRouteObject[]): RouteObject[] => {
    return routes.map((routeObj) => {
      const { title, path, element, children } = routeObj;

      const processedRoute: RouteObject = {
        path,
        element: title ? <PageAnalytics title={title}>{element}</PageAnalytics> : element,
      };

      if (children) {
        processedRoute.children = processRoutes(children);
      }

      return processedRoute;
    });
  };

  return useRoutes(processRoutes(routesObj));
};
