import history from '../history';
import { Colors } from '../themes/colors';
import ConfirmPopup from '../components/shared/ConfirmPopup';
import AnimatedCheckCircle from '../components/shared/AnimatedCheckCircle';
import WarningIcon from '../images/icons/Warning';
import { useNavigate } from 'react-router';

export default function CustomPortal({
  title,
  text,
  route,
  params,
  submitPressed,
  isSuccess,
  isError,
  popupProps,
  buttonText = 'Ok',
  redirect = true,
}) {
  const navigate = useNavigate();
  return (
    <ConfirmPopup
      Icon={() => {
        if (isSuccess) {
          return <AnimatedCheckCircle />;
        }
        if (isError) {
          return <WarningIcon color={Colors.warning_500} width={70} height={70} />;
        }
        return <></>;
      }}
      title={title}
      titleStyle={{ marginTop: isSuccess || isError ? 30 : 0 }}
      description={text}
      descriptionStyle={{ width: '90%', marginBottom: 50 }}
      rightButtonText={buttonText}
      rightButtonAction={() => {
        submitPressed();

        if (!redirect) {
          return;
        }

        if (route === 'back') {
          navigate(-1);
        } else {
          history.push(route, params);
        }
      }}
      {...popupProps}
    />
  );
}
