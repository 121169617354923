import { useNavigate } from 'react-router';
import ArrowDownIcon from '../images/icons/ArrowDown';
import { Colors } from '../themes/colors';

export default function BackButton({ url, onClick, color = Colors.secondary_500 }) {
  const navigate = useNavigate();
  const handleOnClick = () => {
    if (onClick) {
      onClick();
    } else if (url) {
      navigate(url);
    } else {
      navigate(-1);
    }
  };

  return (
    <button
      className="button-reset"
      style={{
        display: 'flex',
        cursor: 'pointer',
        alignItems: 'center',
        width: 'fit-content',
      }}
      type="button"
      onClick={handleOnClick}
    >
      <div style={{ marginRight: 8, transform: 'rotate(90deg)' }}>
        <ArrowDownIcon color={color} />
      </div>
      <span
        className="f-dark"
        style={{
          color,
          fontWeight: 'bold',
          fontSize: 22,
          textAlign: 'left',
        }}
      >
        Back
      </span>
    </button>
  );
}
