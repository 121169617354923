import moment from 'moment';
import { useSelector } from 'react-redux';
import { isPartialShift } from '../../../commonComponents/CommonFunctions';
import { Colors } from '../../../themes/colors';
import { RemoteConfigUtils } from '../../../utils/RemoteConfigUtils';
import { PartialShift } from '../../CandidateList/components/PartialShift';
import { renderCounter, renderOfferExpirationDateTime } from '../../counterJobs/RenderFunc';
import Tooltip from '../../Tooltip';
import { isEmployeeOptionShift } from '../../WorkerClassification/utils';
import { HighlightedLines } from './HighlightedLines';
import { EducationUtils } from '../../../utils/EducationUtils';

const convenienceFeeTooltip =
  'GoTu charges a 3.5% convenience fee on Credit Card transactions. If you do not wish to be charged the convenience fee, please update your billing to E-check/ACH.';

const overtimeTooltip =
  'Overtime pay (1.5x rate) is required for hours that exceed the state’s daily and/or weekly regular working hour limits.';

export const ShiftDetails = ({ currentCounter }) => {
  const user = useSelector((state) => state.user.selectedChildOffice ?? state.user.user);
  const job = useSelector((state) => state.job.job);

  const isCounterHours = isPartialShift(currentCounter);
  const isCounterRate = job?.rate !== currentCounter?.candidateRate;
  const totalAmount = job.total;
  const tempmeeFee = job.tm_fee;
  const tempmeeFeePercent = job.tm_fee_percent;
  const convenienceFee = job.convenience_fee;
  const convenienceFeePercent = job?.convenience_fee_percent?.toFixed(1);
  const chargeDate = moment(job.job_datetime).subtract(28, 'days').format('MM/DD/YYYY');
  const startDiff = moment(job.job_datetime).diff(moment(), 'days');

  const professionName = (() => {
    if (job.subProfession === 'EFDA') {
      return 'Expanded Functions DA';
    }

    return job.professionName;
  })();
  const offerExpirationStates = RemoteConfigUtils.getOfferExpirationStates();
  const shouldShowNewOfferExpirationDateTime = offerExpirationStates?.includes(
    user?.address?.[0]?.state,
  );

  // TODO(lucky): move to CSS to avoid style repetition on render to weigh down HTML to render
  return (
    <div
      className="global_font"
      style={{
        display: 'flex',
        columnGap: 20,
        justifyContent: 'center',
        width: '100%',
        marginBottom: 0,
      }}
    >
      <div
        style={{
          flex: 1,
          maxWidth: 600,
          display: 'flex',
          flexDirection: 'column',
          padding: '24px 24px 40px',
          rowGap: 12,
          backgroundColor: Colors.neutral_50,
        }}
      >
        {isCounterHours && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: -8,
              marginTop: 8,
            }}
          >
            <PartialShift />
          </div>
        )}

        {shouldShowNewOfferExpirationDateTime
          ? renderOfferExpirationDateTime(currentCounter?.expiration, user?.time_zone)
          : renderCounter(isCounterRate, currentCounter?.expiration || new Date())}

        <h1
          style={{
            fontSize: 20,
            margin: '0 0 16px',
            color: Colors.primary_500,
            textAlign: 'center',
          }}
        >
          <b>Shift Details</b>
        </h1>

        <div
          style={{ display: 'flex', justifyContent: 'space-between', color: Colors.neutral_600 }}
        >
          <b>Office:</b>
          <span>{job.offer_owner?.office_name}</span>
        </div>

        <div
          style={{ display: 'flex', justifyContent: 'space-between', color: Colors.neutral_600 }}
        >
          <b>Date:</b>
          <span>{job.local_date}</span>
        </div>

        <div
          style={{ display: 'flex', justifyContent: 'space-between', color: Colors.neutral_600 }}
        >
          <b>Profession:</b>
          <span>{professionName}</span>
        </div>

        <div
          style={{ display: 'flex', justifyContent: 'space-between', color: Colors.neutral_600 }}
        >
          <b>Preferred Specialty:</b>
          <span
            style={{
              textTransform: 'capitalize',
              textAlign: 'right',
            }}
          >
            {EducationUtils.removeSpecialtyPrefix(job?.specialty) || 'General'}
          </span>
        </div>

        <HighlightedLines highlight={isCounterHours}>
          <div
            style={{ display: 'flex', justifyContent: 'space-between', color: Colors.neutral_600 }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <b>Hours:</b>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: Colors.neutral_300,
                  height: 'min-content',
                  padding: '0 5px',
                  marginLeft: 8,
                  borderRadius: 50,
                }}
              >
                <b
                  style={{
                    color: Colors.white,
                    fontSize: 12,
                  }}
                >
                  EST
                </b>
              </div>
            </div>

            <span
              style={{
                textDecoration: isCounterHours ? 'line-through' : 'none',
              }}
            >
              {`${job.local_start_time} - ${job.local_end_time}`}
            </span>
          </div>

          {isCounterHours && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                color: Colors.neutral_600,
              }}
            >
              <b>*Counter Offer (hours):</b>
              <b style={{ textTransform: 'lowercase' }}>
                {`${currentCounter?.local_begin} - ${currentCounter?.local_end}`}
              </b>
            </div>
          )}
        </HighlightedLines>

        {isCounterHours && <div style={{ marginTop: -20 }} />}

        <HighlightedLines highlight={isCounterRate}>
          <div
            style={{ display: 'flex', justifyContent: 'space-between', color: Colors.neutral_600 }}
          >
            <b>Hourly Rate:</b>
            <span
              style={{
                textDecoration:
                  job?.rate !== currentCounter?.candidateRate ? 'line-through' : 'none',
              }}
            >
              {`$${job.rate.toFixed(2)}`}
            </span>
          </div>

          {isCounterRate ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                color: Colors.neutral_600,
              }}
            >
              <b>*Counter Offer (rate):</b>
              <b>{`$${currentCounter?.candidateRate?.toFixed(2)}`}</b>
            </div>
          ) : (
            <></>
          )}
        </HighlightedLines>

        <div
          style={{ display: 'flex', justifyContent: 'space-between', color: Colors.neutral_600 }}
        >
          <b>Meal Break:</b>
          <span>
            {job.lunch_final_break_time} min {job.is_lunch_break_paid ? ' (Paid)' : ' (Unpaid)'}
          </span>
        </div>

        <HighlightedLines highlight={job?.regularAndOvertimeHours?.overtime > 0}>
          {job?.regularAndOvertimeHours?.regular > 0 && job?.totalRegular > 0 && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                color: Colors.neutral_600,
              }}
            >
              <b>Regular Rate Pay ({job?.regularAndOvertimeHours?.regular?.toFixed(2)} hrs):</b>

              <span>{`$${job?.totalRegular?.toFixed(2)}`}</span>
            </div>
          )}
          {job?.regularAndOvertimeHours?.overtime > 0 && job?.totalOvertime > 0 && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                color: Colors.neutral_600,
              }}
            >
              <b style={{ display: 'flex' }}>
                *Overtime Pay ({job?.regularAndOvertimeHours?.overtime?.toFixed(2)} hrs):
                <span
                  style={{
                    marginLeft: '5px',
                    position: 'relative',
                    bottom: '2.5px',
                    marginBottom: -10,
                  }}
                >
                  <Tooltip content={overtimeTooltip} />
                </span>
              </b>

              <b>{`$${job?.totalOvertime?.toFixed(2)}`}</b>
            </div>
          )}
        </HighlightedLines>

        <div
          style={{ display: 'flex', justifyContent: 'space-between', color: Colors.neutral_600 }}
        >
          {tempmeeFeePercent !== 0 ? (
            <b>
              Service Fee ({tempmeeFeePercent}
              %):
            </b>
          ) : (
            <b>Service Fee:</b>
          )}

          <span>{`$${tempmeeFee.toFixed(2)}`}</span>
        </div>

        {job.employeeOptionFee !== 0 && isEmployeeOptionShift(job) && (
          <div
            style={{ display: 'flex', justifyContent: 'space-between', color: Colors.neutral_600 }}
          >
            <b>Employee Fee:</b>
            <span className="global_font">
              {job.employeeOptionFee ? `$${job.employeeOptionFee?.toFixed(2)}` : '$--.--'}
            </span>
          </div>
        )}

        {job.convenience_fee !== 0 ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: -8,
              color: Colors.neutral_600,
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <b>Convenience Fee {`(${convenienceFeePercent || '0'}%)`}:</b>
              <span
                style={{
                  marginLeft: '5px',
                  position: 'relative',
                  bottom: '2.5px',
                }}
              >
                <Tooltip content={convenienceFeeTooltip} />
              </span>
            </div>
            <span>{`$${convenienceFee.toFixed(2) || '0'}`}</span>
          </div>
        ) : null}

        {job?.multi_day_booking && startDiff > 28 ? (
          <div
            style={{ display: 'flex', justifyContent: 'space-between', color: Colors.neutral_600 }}
          >
            <b>Professional Pay Charge Date:</b>
            <span>{chargeDate}</span>
          </div>
        ) : null}

        {job.counterCover ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              color: Colors.neutral_600,
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <b>Gotu Counter Cover:</b>
            </div>
            <span>-$ {job.counterCover.amount.toFixed(2)}</span>
          </div>
        ) : null}

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            color: Colors.neutral_600,
          }}
        >
          <b
            style={{
              color: Colors.success_500,
              display: 'flex',
              alignItems: 'center',
              columnGap: 5,
              fontSize: 16,
            }}
          >
            Total Pay:
          </b>

          <b style={{ color: Colors.neutral_600 }}>${totalAmount.toFixed(2)}</b>
        </div>
      </div>
    </div>
  );
};
