import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  GET_CHILD_OFFICES_ACTION,
  INITIAL_LOADING,
  getChildOffices,
  hideCreateJobMessage,
  hideMessage,
  initialLoading,
} from './actions';
import { createLoadingSelector } from './api/selectors';
import { Box } from './commonComponents';
import CreateJobPortal from './commonComponents/CreateJobPortal';
import CustomPortal from './commonComponents/CustomPortal';
import CustomSpinner from './commonComponents/CustomSpinner';
import ErrorShower from './commonComponents/ErrorShower';
import UnavalibleMobile from './commonComponents/UnavalibleMobile';
import { AcceptTermsPopup } from './components/AcceptTermsPopup';
import { ConfirmDualModelTermsPopup } from './components/ConfirmDualModelTermsPopup/ConfirmDualModelTermsPopup';
import { NewBadgeAnnouncementPopup } from './components/NewBadgeAnnouncementPopup';
import { NewPhotoUploadFeaturePopup } from './components/NewPhotoUploadFeaturePopup';
import { OfficeEfdaOnboardingPopup } from './components/OfficeEfdaOnboardingPopup';
import { OfficeFrontDeskOnboardingPopup } from './components/OfficeFrontDeskOnboardingPopup';
import { OfficeTimePerPatientPopup } from './components/OfficeTimePerPatientPopup/OfficeTimePerPatientPopup';
import ParkingInfoPopup from './components/ParkingInfoPopup';
import { SuccessRegistrationPopup } from './components/SuccessRegistrationPopup';
import { SuccessPopup as SuccessWorkerClassificationPopup } from './components/WorkerClassification/components/SuccessPopup';
import { growthbook, shouldUseDsoStructure } from './growthbook';
import { AuthenticatedRoutes } from './routes/AuthenticatedRoutes';
import { UnauthenticatedRoutes } from './routes/UnauthenticatedRoutes';
import { UserType } from './enums/UserType';
import { ReferralRewardPopup } from './components/ReferralRewardPopup';
import { useCookieYes } from './hooks/useCookieYes';

export default function App() {
  const dispatch = useDispatch();
  const loadingSelector = createLoadingSelector([INITIAL_LOADING, GET_CHILD_OFFICES_ACTION]);
  const isLoading = useSelector((state) => loadingSelector(state));
  const userLogged = useSelector((state) => state.user.user);
  const user = useSelector((state) => state.user.selectedChildOffice);
  const message = useSelector((state) => state.message.message);
  const [requestedUser, setRequestedUser] = useState(false);

  const isMobileTablet = window.innerWidth <= 760;

  useCookieYes();

  useEffect(() => {
    dispatch(initialLoading());
    setRequestedUser(true);
  }, [dispatch]);

  useEffect(() => {
    if (requestedUser && user) {
      growthbook.setAttributes({
        id: user?.id,
        userType: user?.user_type,
        ...(user?.address && user?.address[0] && { state: user.address[0].state }),
      });
    }
  }, [user, requestedUser]);

  useEffect(() => {
    if (
      shouldUseDsoStructure() &&
      userLogged?.is_profile_complete &&
      userLogged?.user_type === UserType.DNT
    ) {
      dispatch(getChildOffices());
    }
  }, [dispatch, userLogged?.user_type, userLogged?.is_profile_complete]);

  const { text, title, route, params, isSuccess, isError, popupProps, jobData } = message;
  if (isMobileTablet) {
    return <UnavalibleMobile />;
  }

  if (isLoading || !requestedUser) {
    return <CustomSpinner isLoading={isLoading} />;
  }

  if (requestedUser && (!user || !user.is_profile_complete || user.user_type !== 'DNT')) {
    return (
      <Box>
        <UnauthenticatedRoutes />
        <ErrorShower />
        {text || title ? (
          <CustomPortal
            isSuccess={isSuccess}
            isError={isError}
            popupProps={popupProps}
            title={title}
            text={text}
            route={route}
            params={params}
            submitPressed={() => dispatch(hideMessage())}
          />
        ) : null}
        <SuccessWorkerClassificationPopup />
      </Box>
    );
  }

  return (
    <>
      <AuthenticatedRoutes />
      {text || title ? (
        <CustomPortal
          isSuccess={isSuccess}
          isError={isError}
          popupProps={popupProps}
          title={title}
          text={text}
          route={route}
          params={params}
          submitPressed={() => dispatch(hideMessage())}
        />
      ) : null}
      {jobData && (
        <CreateJobPortal
          jobData={jobData}
          route={route}
          params={params}
          submitPressed={() => dispatch(hideCreateJobMessage())}
        />
      )}
      <ConfirmDualModelTermsPopup />
      <ParkingInfoPopup />
      <SuccessRegistrationPopup />
      <AcceptTermsPopup />
      <SuccessWorkerClassificationPopup />
      <OfficeTimePerPatientPopup />
      <NewPhotoUploadFeaturePopup />
      <OfficeEfdaOnboardingPopup />
      <OfficeFrontDeskOnboardingPopup />
      <NewBadgeAnnouncementPopup />
      <ReferralRewardPopup />
      <ErrorShower />
    </>
  );
}
