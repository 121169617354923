import { Navigate } from 'react-router';
import ForgotPassword from '~/components/authentication/ForgotPassword';
import UserAuth from '~/components/authentication/UserAuth';
import { AdditionalOfficeInformation } from '~/components/registration/AdditionalOfficeInformation';
import { ContactInformation } from '~/components/registration/ContactInformation';
import { GeneralOfficeInformation } from '~/components/registration/GeneralOfficeInformation';
import { WorkerClassification as WorkerClassificationSignUp } from '~/components/registration/WorkerClassification';
import { routes } from '..';
import { useAppRoutes } from '../hooks';

export const UnauthenticatedRoutes = () => {
  return useAppRoutes([
    { path: routes.login, title: 'Login', element: <UserAuth /> },
    { path: routes.forgotPassword, title: 'Forgot Password', element: <ForgotPassword /> },
    {
      title: 'Registration - Contact Information',
      path: routes.contactInformation,
      element: <ContactInformation />,
    },
    {
      title: 'Registration - General Office Information',
      path: routes.generalOfficeInformation,
      element: <GeneralOfficeInformation />,
    },
    {
      title: 'Registration - Additional Office Information',
      path: routes.additionalOfficeInformation,
      element: <AdditionalOfficeInformation isRegistration />,
    },
    {
      title: 'Registration - Worker Classification',
      path: routes.workerClassification,
      element: <WorkerClassificationSignUp />,
    },
    { path: '*', element: <Navigate to={routes.login} /> },
  ]);
};
