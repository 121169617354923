import Button from '../../commonComponents/Button';
import ClosePopupIcon from '../../images/ClosePopupIcon.svg';
import { Colors } from '../../themes/colors';

export const ConfirmPopupContent = ({
  rightButtonAction,
  leftButtonAction,
  rightButtonText,
  leftButtonText,
  title,
  titleStyle,
  description,
  descriptionStyle,
  Icon,
  closePopup,
  showClosePopupIcon,
  children,
  leftButtonStyle,
  rightButtonStyle,
  rightButtonDisabled,
  leftButtonDisabled,
  rightButtonLoading,
}) => {
  const renderDescription = () => {
    if (!description) {
      return null;
    }

    if (typeof description === 'function') {
      const DescriptionComponent = description;
      return (
        <div
          style={{
            marginBottom: 70,
          }}
        >
          <DescriptionComponent />
        </div>
      );
    }

    return (
      <p
        style={{
          width: '80%',
          fontFamily: 'Nunito',
          fontSize: 16,
          color: Colors.neutral_500,
          textAlign: 'center',
          margin: 0,
          marginBottom: 70,
          ...descriptionStyle,
        }}
      >
        {description}
      </p>
    );
  };

  return (
    <>
      {showClosePopupIcon && (
        <img
          alt="close-popup-icon"
          src={ClosePopupIcon}
          style={{
            position: 'absolute',
            marginTop: -24,
            marginRight: 16,
            cursor: 'pointer',
            alignSelf: 'flex-end',
          }}
          onClick={closePopup}
        />
      )}

      {Icon && <Icon />}

      {title && (
        <h1
          style={{
            fontFamily: 'Nunito',
            fontWeight: 'bold',
            fontSize: 18,
            color: Colors.neutral_600,
            margin: 0,
            marginBottom: 20,
            textAlign: 'center',
            ...titleStyle,
          }}
        >
          {title}
        </h1>
      )}

      {renderDescription()}

      {children}

      <div style={{ display: 'flex', columnGap: 16 }}>
        {leftButtonText && (
          <Button
            style={{
              alignSelf: 'center',
              width: 152,
              margin: 0,
              backgroundColor: Colors.secondary_500,
              opacity: leftButtonDisabled ? 0.5 : 1,
              ...leftButtonStyle,
            }}
            onClick={leftButtonAction}
            disabled={leftButtonDisabled}
            text={leftButtonText}
          />
        )}

        {rightButtonText && (
          <Button
            type="button"
            style={{
              alignSelf: 'center',
              width: 152,
              margin: 0,
              backgroundColor: Colors.primary_500,
              opacity: rightButtonDisabled ? 0.5 : 1,
              ...rightButtonStyle,
            }}
            onClick={rightButtonAction}
            disabled={rightButtonDisabled}
            text={rightButtonText}
            isLoading={rightButtonLoading}
          />
        )}
      </div>
    </>
  );
};
