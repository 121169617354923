import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import {
  closeOverlappingModal,
  editJobInfoMdb,
  hideJobCreatePaymentError,
  postInviteToMultiDayJob,
} from '../../../../actions';
import { trackEvent } from '../../../../api/analytics';
import { isDailyOvertime } from '../utils';
import history from '~/history';

export const useConfirmStep = () => {
  const dispatch = useDispatch();
  const [showLeavingPopup, setShowLeavingPopup] = useState(false);
  const navigate = useNavigate();
  const jobInfo = useSelector((state) => state.multiday.jobInfo);
  const job = useSelector((state) => state.job);
  const showJobCreatePaymentError = useSelector((state) => state.job.showJobCreatePaymentError);
  const user = useSelector((state) => state.user.selectedChildOffice);
  const workerClassificationType = user?.workerClassification?.classificationType;
  const overtimeLimits = user?.overtimeLimits?.[workerClassificationType];
  const hasOvertimeDay = jobInfo.some((job) => isDailyOvertime(job, overtimeLimits?.daily));
  const [showOvertimeLimitPopup, setShowOvertimeLimitPopup] = useState(false);

  const handleConfirmButton = ({ alertOvertime = true }) => {
    if (alertOvertime && overtimeLimits && hasOvertimeDay) {
      setShowOvertimeLimitPopup(true);
      return;
    }

    trackEvent(`${jobInfo[0].professional?.id ? 'Re-Book' : 'Temp'} Request - Submitted`, {
      jobInfo,
    });
    dispatch(postInviteToMultiDayJob());
  };

  const confirmOverlapping = () => {
    for (let i = 0; i < jobInfo.length; i += 1) {
      const jobData = jobInfo[i];
      dispatch(editJobInfoMdb(jobData.localDate, { ...jobData, duplicate: true }));
    }
    dispatch(closeOverlappingModal());
    handleConfirmButton({ alertOvertime: false });
  };

  const cancelRequest = () => {
    history.push('/');
  };

  const hidePaymentError = () => {
    navigate(-1);
    dispatch(hideJobCreatePaymentError());
  };

  const updatePayment = () => {
    history.replace('/');
    history.push('/dashboard/payment');
    dispatch(hideJobCreatePaymentError());
  };

  return {
    jobInfo,
    job,
    showLeavingPopup,
    showJobCreatePaymentError,
    setShowLeavingPopup,
    handleConfirmButton,
    confirmOverlapping,
    cancelRequest,
    hidePaymentError,
    updatePayment,
    showOvertimeLimitPopup,
    setShowOvertimeLimitPopup,
  };
};
