import { Navigate, Outlet } from 'react-router';
import { Column, Row } from '~/commonComponents';
import Footer from '~/commonComponents/footer';
import About from '~/components/About';
import { AccountInformation } from '~/components/AccountInformation/AccountInformation';
import Alerts from '~/components/Alerts';
import CancellationFeedback from '~/components/CancellationFeedback';
import { ConfirmCandidate } from '~/components/ConfirmCandidate/ConfirmCandidate';
import Dashboard from '~/components/Dashboard/Dashboard';
import InvoiceList from '~/components/Dashboard/ReceiptList/InvoiceList';
import ReceiptList from '~/components/Dashboard/ReceiptList/ReceiptList';
import TransactionsInfo from '~/components/Dashboard/ReceiptList/TransactionsInfo';
import EndOfDay from '~/components/EndOfDay';
import ExpiredCounterOffers from '~/components/ExpiredCounterOffers';
import Help from '~/components/Help';
import HowItWorks from '~/components/HowItWorks';
import Inbox from '~/components/Inbox';
import JobDetails from '~/components/JobDetails/JobDetails';
import { ShiftList } from '~/components/JobList/ShiftList/ShiftList';
import { TodayJobs } from '~/components/JobList/TodayJobs';
import JobSummaryRating from '~/components/JobSummaryRating';
import LocumTenensDentist from '~/components/LocumTenensDentist';
import { NPSReview } from '~/components/NPSReview/NPSReview';
import NotificationSettings from '~/components/NotificationSettings/NotificationSettings';
import Payment from '~/components/Payments/Payment';
import PermanentDashboard from '~/components/PermanentJobs/PermanentDashboard';
import PermanentsJobs from '~/components/PermanentJobs/PermanentsJobs';
import { ProfessionalProfile } from '~/components/ProfessionalProfile/ProfessionalProfile';
import { ProfessionalsHub } from '~/components/ProfessionalsHub/ProfessionalsHub';
import { InviteProfessional } from '~/components/ProfessionalsHub/components/InviteProfessional/InviteProfessional';
import { OfficeJobInvites } from '~/components/ProfessionalsHub/components/OfficeJobInvites/OfficeJobInvites';
import ReferralScreen from '~/components/Referral/ReferralScreen';
import AdjustHoursScreen from '~/components/Request/AdjustHoursScreen';
import {
  EditShiftContent,
  ReviewContent,
  ShiftDetailsContent,
} from '~/components/Request/multiday/screens';
import PPScreen from '~/components/Request/pp/PPScreen';
import { EditOpenJob } from '~/components/Request/temp/EditOpenJob';
import { Schedule } from '~/components/Schedule/Schedule';
import { SendFeedback } from '~/components/SendFeedback';
import SideBar from '~/components/SideBar';
import TopNavBar from '~/components/TopNavBar';
import VirtualJobRedirection from '~/components/VirtualJobRedirection';
import { WorkerClassification } from '~/components/WorkerClassification';
import { WorkerClassificationManagement } from '~/components/WorkerClassificationManagement';
import AdjustmentScreen from '~/components/adjustments/AdjustmentScreen';
import OpenJobScreen from '~/components/counterJobs/OpenJobScreen';
import Chat from '~/components/messages/Chat';
import Profile from '~/components/profile/Profile';
import {
  getShouldEnableReferralProgramV2,
  shouldShowConfirmCandidateInBulk,
  showDecoupledDntEodFlow,
} from '~/growthbook';
import { useAppSelector } from '~/hooks/useAppSelector';
import { routes } from '..';
import { useAppRoutes } from '../hooks';
import { BulkConfirmCandidateJobs } from '~/components/ConfirmJobs/BulkConfirmCandidateJobs';
import { ReferralScreenV2 } from '~/components/Referral/ReferralScreenV2';
import { ReferralDisclaimerScreen } from '~/components/Referral/ReferralDisclaimerScreen';

export const AuthenticatedRoutes = () => {
  const user = useAppSelector((state) => state.user.selectedChildOffice);
  const missingWorkerClassification =
    user?.isWorkerClassificationEnabled && !user?.workerClassification?.classificationType;
  const allowedRequest = user?.allowedRequest || {};

  return useAppRoutes([
    {
      path: '/',
      element: (
        <Column flex={1} justifyContent="space-between" minHeight={'100%'}>
          <TopNavBar />
          <Row flex={1}>
            <SideBar />
            <Outlet />
          </Row>
          <Footer />
        </Column>
      ),
      children: [
        {
          title: 'Dashboard',
          path: routes.dashboardHome,
          element: <Dashboard />,
        },
        {
          title: 'Account Information',
          path: routes.accountInformation,
          element: <AccountInformation />,
        },
        {
          title: 'Invoices',
          path: routes.invoices,
          element: <InvoiceList />,
        },
        {
          title: 'Edit Open Job',
          path: routes.editOpenJob,
          element: <EditOpenJob />,
        },
        {
          title: 'Permanent Job Details',
          path: routes.permanentJobDetails,
          element: <PermanentDashboard />,
        },
        {
          title: 'Permanent Jobs',
          path: routes.permanentJobs,
          element: <PermanentsJobs />,
        },
        {
          title: 'Inbox',
          path: routes.inbox,
          element: (
            <div className="component_container inbox_screen">
              <div className="floating_container">
                <Inbox />
              </div>
            </div>
          ),
        },
        {
          title: 'Chat',
          path: routes.chat,
          element: <Chat />,
        },
        {
          title: 'Expired Counter Offers',
          path: routes.expiredCounterOffers,
          element: <ExpiredCounterOffers />,
        },
        {
          title: 'Adjust Hours',
          path: routes.adjustHours,
          element: <AdjustHoursScreen />,
        },
        // @ts-expect-error -- not typed
        ...(allowedRequest?.tempOrMdbShifts && !missingWorkerClassification
          ? [
              {
                title: 'Multi Day Shift Request',
                path: routes.multiDayShiftRequest,
                element: <ShiftDetailsContent />,
              },
            ]
          : []),
        {
          title: 'Multi Day Shift Edit',
          path: routes.multiDayShiftEdit,
          element: <EditShiftContent />,
        },
        {
          title: 'Multi Day Shift Confirm',
          path: routes.multiDayShiftConfirm,
          element: <ReviewContent />,
        },
        {
          title: 'Permanent Hire Request',
          path: routes.permanentHireRequest,
          element: <PPScreen />,
        },
        {
          title: 'Transaction History Details',
          path: routes.transactionHistoryDetails,
          element: <TransactionsInfo />,
        },
        {
          title: 'Payment History',
          path: routes.paymentHistory,
          element: <ReceiptList />,
        },
        {
          title: "Today's Jobs",
          path: routes.todaysJobs,
          element: <TodayJobs />,
        },
        {
          title: 'Shifts Overview',
          path: routes.shiftsOverview,
          element: <ShiftList />,
        },
        {
          path: routes.virtualJobRedirection,
          element: <VirtualJobRedirection />,
        },
        {
          title: 'Professional Profile',
          path: routes.professionalProfile,
          element: <ProfessionalProfile />,
        },
        {
          title: 'Confirm Candidate',
          path: routes.confirmCandidate,
          element: <ConfirmCandidate />,
        },
        {
          title: 'Job Summary',
          path: routes.jobSummary,
          element: <EndOfDay />,
        },
        ...(showDecoupledDntEodFlow()
          ? [
              {
                title: 'Job Summary Rating',
                path: routes.jobSummaryRating,
                element: <JobSummaryRating />,
              },
            ]
          : []),
        {
          title: 'Job Details',
          path: routes.jobDetails,
          element: <JobDetails />,
        },
        {
          title: 'Open Job Details',
          path: routes.openJobDetails,
          element: <OpenJobScreen />,
        },
        {
          title: 'NPS Review',
          path: routes.npsReview,
          element: <NPSReview />,
        },
        {
          title: 'Alerts',
          path: routes.alerts,
          // @ts-expect-error -- not typed
          element: <Alerts />,
        },
        {
          title: 'Schedule',
          path: routes.schedule,
          element: <Schedule />,
        },
        {
          title: 'Payment Information',
          path: routes.paymentInformation,
          element: <Payment />,
        },
        {
          title: 'Help',
          path: routes.help,
          element: <Help />,
        },
        {
          title: 'About',
          path: routes.about,
          element: <About />,
        },
        {
          title: 'Notification Settings',
          path: routes.notificationSettings,
          element: <NotificationSettings />,
        },
        {
          title: 'Cancellation Feedback',
          path: routes.cancellationFeedback,
          element: <CancellationFeedback />,
        },
        {
          title: 'Send Feedback',
          path: routes.sendFeedback,
          element: <SendFeedback />,
        },
        {
          title: 'Job Adjustment',
          path: routes.jobAdjustment,
          element: <AdjustmentScreen />,
        },
        {
          title: 'How It Works',
          path: routes.howItWorks,
          element: <HowItWorks />,
        },
        {
          title: 'Locum Tenens Dentist',
          path: routes.locumTenensDentist,
          element: <LocumTenensDentist />,
        },
        {
          title: 'Worker Type Setting',
          path: routes.workerClassificationType,
          element: <WorkerClassification isRegistration={false} />,
        },
        {
          title: 'Worker Classification Management',
          path: routes.workerClassificationManagement,
          element: <WorkerClassificationManagement />,
        },
        {
          title: 'Professionals Hub',
          path: routes.professionalsHub,
          element: <ProfessionalsHub />,
        },
        {
          title: 'Invite Professional',
          path: routes.inviteProfessional,
          element: <InviteProfessional />,
        },
        {
          title: 'Office Job Invites',
          path: routes.officeJobInvites,
          element: <OfficeJobInvites />,
        },
        {
          title: 'Account',
          path: routes.account,
          element: <Profile />,
        },
        {
          path: '*',
          element: <Navigate to={routes.login} />,
        },
        ...(shouldShowConfirmCandidateInBulk()
          ? [
              {
                title: 'Bulk Confirm Candidate Jobs',
                path: routes.bulkConfirmCandidateJobs,
                element: <BulkConfirmCandidateJobs />,
              },
              {
                title: "Candidate's Job Detail",
                path: routes.candidateJobDetail,
                element: <ConfirmCandidate isSingleView />,
              },
            ]
          : []),
        ...(getShouldEnableReferralProgramV2()
          ? [
              {
                title: 'Referral Program',
                path: routes.referral,
                element: <ReferralScreenV2 />,
              },
              {
                title: 'Referral Program Disclaimer',
                path: routes.referralDisclaimer,
                element: <ReferralDisclaimerScreen />,
              },
            ]
          : [
              {
                title: 'Referral',
                path: routes.referral,
                element: <ReferralScreen />,
              },
            ]),
      ],
    },
  ]);
};
