import { CSSProperties, MouseEvent, PropsWithChildren } from 'react';

import './Pressable.scss';

interface IProps {
  onPress: (event: MouseEvent<HTMLButtonElement>) => void;
  style?: CSSProperties;
}

export const Pressable = ({ onPress, children, style }: PropsWithChildren<IProps>) => {
  const handleClick = (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
    if (onPress) onPress(event);
  };

  return (
    <button type="button" className="pressable" onClick={handleClick} style={style}>
      {children}
    </button>
  );
};
