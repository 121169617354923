import { motion } from 'framer-motion';

import Portal from '../../commonComponents/Portal';
import { ConfirmPopupContent } from './ConfirmPopupContent';

export default function ConfirmPopup({
  rightButtonAction,
  leftButtonAction,
  rightButtonText,
  leftButtonText,
  title,
  titleStyle,
  description,
  descriptionStyle,
  Icon,
  closePopup,
  showClosePopupIcon,
  modalStyle,
  children,
  leftButtonStyle,
  rightButtonStyle,
  rightButtonDisabled,
  leftButtonDisabled,
  rightButtonLoading,
}) {
  return (
    <Portal>
      <div className="modal" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }} onClick={closePopup}>
        <motion.div
          onClick={(event) => event.stopPropagation()}
          className="modal_content"
          style={{ position: 'relative', padding: '45px 10px', ...modalStyle }}
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          <ConfirmPopupContent
            rightButtonAction={rightButtonAction}
            leftButtonAction={leftButtonAction}
            rightButtonText={rightButtonText}
            leftButtonText={leftButtonText}
            title={title}
            titleStyle={titleStyle}
            description={description}
            descriptionStyle={descriptionStyle}
            Icon={Icon}
            closePopup={closePopup}
            showClosePopupIcon={showClosePopupIcon}
            leftButtonStyle={leftButtonStyle}
            rightButtonStyle={rightButtonStyle}
            rightButtonDisabled={rightButtonDisabled}
            leftButtonDisabled={leftButtonDisabled}
            rightButtonLoading={rightButtonLoading}
          >
            {children}
          </ConfirmPopupContent>
        </motion.div>
      </div>
    </Portal>
  );
}
