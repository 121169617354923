import moment from 'moment';
import { Box, Center, Column, Pressable, Row, Text } from '~/commonComponents';
import Checkbox from '~/commonComponents/Checkbox';
import CheckCircle from '~/commonComponents/Checkcircle';
import history from '~/history';
import { IJob } from '~/models';
import { ShiftListSelectionMode } from './enums';

// @ts-expect-error - unable to resolve path
import ChevronRight from '~/images/arrow_right.svg';
import { useDispatch } from 'react-redux';
import { hideConfirmCandidateAnotherJobPopup } from '~/actions';

interface IProps {
  isChecked: boolean;
  job: IJob;
  mode: ShiftListSelectionMode;
  handleToggleOption: (jobId?: string) => void;
}

export const ShiftItem = ({ isChecked = false, job, mode, handleToggleOption }: IProps) => {
  const dispatch = useDispatch();

  const { multipleCounters } = job;
  const offerCounter = multipleCounters[0];

  const hasCounterHours =
    offerCounter?.begin !== offerCounter?.originalBegin ||
    offerCounter?.end !== offerCounter?.originalEnd;
  const hasCounterRates = offerCounter?.rate !== offerCounter?.originalRate;

  return (
    <Row className="shift-item" height={64}>
      <Pressable
        style={{
          width: '10%',
        }}
        onPress={() => handleToggleOption(job.id)}
      >
        <Center height={64} flex={1}>
          {mode === ShiftListSelectionMode.Multiple ? (
            <Checkbox checked={isChecked} />
          ) : (
            <CheckCircle checked={isChecked} />
          )}
        </Center>
      </Pressable>
      <Pressable
        style={{
          flex: 1,
        }}
        onPress={() => {
          dispatch(hideConfirmCandidateAnotherJobPopup());
          history.push(`/dashboard/candidates/${offerCounter.candidate.id}/jobs/${job.id}`);
        }}
      >
        <Row height={64} flex={1} alignItems="center">
          <Box flex={1}>
            <Text>{moment(job?.job_datetime).format('ddd, MMM DD, YYYY')}</Text>
          </Box>
          <Column flex={1}>
            <Text bold>{job?.professionName}</Text>
            <Text>{job?.profession}</Text>
          </Column>
          <Column flex={1}>
            <Text textDecoration={hasCounterHours ? 'line-through' : 'none'}>
              {job.local_start_time} - {job.local_end_time}
            </Text>
            {hasCounterHours && (
              <Text bold>
                {offerCounter?.local_begin} - {offerCounter?.local_end}
              </Text>
            )}
          </Column>
          <Column flex={1}>
            <Text textDecoration={hasCounterRates ? 'line-through' : 'none'}>
              {`$${offerCounter?.originalRate?.toFixed(2)}`}
            </Text>
            {hasCounterRates && <Text bold>{`$${offerCounter?.rate?.toFixed(2)}`}</Text>}
          </Column>
          <Box flex={1}>
            <Text bold>{`$${job?.total?.toFixed(2)}`}</Text>
          </Box>
          <Center width={'11.2%'}>
            <img src={ChevronRight} alt="arrow" style={{ width: 16, height: 16 }} />
          </Center>
        </Row>
      </Pressable>
    </Row>
  );
};
