import { motion } from 'framer-motion';
import { useParams } from 'react-router';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import history from '../../../../history';
import { CandidateCard } from './CandidateCard';
import { useJobCandidates } from '../../hooks/useJobCandidates';
import { getShouldEnableUnlimitedCandidatesList } from '../../../../growthbook';
import LeftArrowIcon from '../../../../images/circle-left-arrow-icon.svg';
import RightArrowIcon from '../../../../images/circle-right-arrow-icon.svg';

export const CandidateSelection = () => {
  const { jobId, candidateId } = useParams();
  const { allCandidates } = useSelector((state) => state.job);
  const {
    candidates,
    bestCandidates,
    isFirstPage,
    isLastPage,
    loadPrevPageCandidates,
    loadNextPageCandidates,
  } = useJobCandidates();

  const [isHovered, setIsHovered] = useState(false);

  const isUnlimitedCandidatesListEnabled = getShouldEnableUnlimitedCandidatesList();

  const handleOnClick = (id) => {
    history.replace(`/dashboard/job/${jobId}/confirm/${id}`);
  };

  const isBestPick = useCallback(
    (candidateId) => bestCandidates.some((candidate) => candidate?.id === candidateId),
    [bestCandidates],
  );

  if (allCandidates?.length === 1) {
    return null;
  }

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        position: 'relative',
        alignItems: 'center',
        display: 'flex',
        width: '100%',
        gap: 8,
      }}
    >
      {isUnlimitedCandidatesListEnabled && !isFirstPage && (
        <img
          src={LeftArrowIcon}
          alt="left-arrow"
          style={{
            cursor: 'pointer',
            position: 'absolute',
            zIndex: 1,
            left: 0,
            opacity: isHovered ? 1 : 0,
            transition: 'opacity 0.3s ease',
          }}
          onClick={loadPrevPageCandidates}
        />
      )}

      {candidates?.map((item) => (
        <motion.div
          key={item.id}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
          style={{ flex: 1, maxWidth: '20%' }}
        >
          <CandidateCard
            item={item}
            isSelected={candidateId === item?.candidate?.id}
            onClick={() => handleOnClick(item?.candidate?.id)}
            isBestPick={false}
            containerStyle={{ width: '100%' }}
          />
        </motion.div>
      ))}

      {isUnlimitedCandidatesListEnabled && !isLastPage && (
        <img
          src={RightArrowIcon}
          alt="right-arrow"
          style={{
            cursor: 'pointer',
            position: 'absolute',
            zIndex: 1,
            right: 0,
            marginRight: 15,
            opacity: isHovered ? 1 : 0,
            transition: 'opacity 0.3s ease',
          }}
          onClick={loadNextPageCandidates}
        />
      )}
    </div>
  );
};
