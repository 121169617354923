import {
  IAddress,
  IAllowedRequest,
  IBackgroundCheckInfo,
  ICancellationHistory,
  ICancellationPolicy,
  IDentistAdminInfo,
  IDeviceToken,
  IEdits,
  IFavoriteProfessionalHistory,
  ILegalIdentityInformation,
  ILicense,
  IMedMalInsuranceSurvey,
  IMedMalSurvey,
  INotificationSettings,
  INpsReview,
  IOfficeInfo,
  IParkingInformation,
  IPartnerReferral,
  IPaymentMethod,
  IProfessionalInfo,
  IUserAvailability,
  IUserNote,
  IUserReferral,
  IWorkerClassification,
  IWorkerClassificationRequest,
  MedMalSurveyStatusEnum,
  W9Status,
} from '.';

export interface IUser {
  readonly _id: string;
  readonly id: string;
  readonly first_name: string;
  readonly last_name: string;
  readonly time_zone?: string;
  readonly full_name: string;
  readonly user_type: UserTypeEnum;
  readonly stripe_id?: string;
  readonly stripe_connected_account_id?: string;
  readonly stripe_connected_account_id_for_referral?: string;
  readonly stripe_tos_accepted?: Date;
  readonly stripe_tos_accepted_for_referral: Date;
  readonly stripe_billing_email: string;
  readonly original: UserOriginEnum;
  readonly email_id: string;
  readonly email_lower?: string;
  readonly emailStatus: EmailStatus;
  readonly receipt_enabled: boolean;
  readonly w9_form?: string;
  readonly w9_panda_doc: string;
  readonly w9_docusign_envelope_id: string;
  readonly resume: string;
  readonly password?: string;
  readonly office_notes?: string;
  readonly admin_notes?: string;
  readonly date_of_birth: string;
  readonly office_name: string;
  readonly main_office_number?: string;
  readonly after_hours_number?: string;
  readonly contact_number: string;
  readonly contact_number_unconfirmed?: string;
  readonly sms_code?: string;
  readonly sms_code_sent?: Date;
  readonly profile_image: string;
  readonly device_tokens?: IDeviceToken[];
  readonly device_type: string;
  readonly client_version: string;
  readonly last_time_sign_in: Date;
  readonly is_profile_complete: boolean;
  readonly created_at: Date;
  readonly verification_code?: string;
  readonly password_reset_code?: string;
  readonly is_notes_for_temp: boolean;
  readonly edits: IEdits[];
  readonly web_site?: string;
  readonly linkedin_url?: string;
  readonly current_employer: string;
  readonly isAdmin?: boolean;
  readonly hyg_licen_photo?: string;
  readonly hyg_ident_state_issued_id?: string;
  readonly hyg_ident_state_issued?: string;
  readonly hyg_ident_first_issue_date?: string;
  readonly hyg_ident_expiration_date?: string;
  readonly hyg_ident_photo?: string;
  readonly licenses: ILicense[];
  readonly licenseTypes: string[];
  readonly address: IAddress[];
  readonly account_standing: UserStatusEnum;
  readonly notes_for_temp?: string;
  readonly notification_settings: INotificationSettings;
  readonly average_rating: number;
  readonly tsOffset?: number;
  readonly account_admin: IDentistAdminInfo[];
  readonly blocked_hygienist: string[];
  readonly is_pre_approved?: boolean;
  readonly info_filled?: Date;
  readonly office_info: IOfficeInfo;
  readonly professional_info: IProfessionalInfo;
  readonly payment_methods?: IPaymentMethod[];
  readonly payment_methods_for_referral?: IPaymentMethod[];
  readonly nps_reviews?: INpsReview[];
  readonly availability: IUserAvailability;
  readonly ram?: IUser;
  readonly notes?: IUserNote[];
  readonly descend_offices: IUser[];
  readonly referral?: IUserReferral[];
  readonly ram_zips: string[];
  readonly referralId: string;
  readonly gift_email: string;
  readonly referralRewardAmount?: number;
  readonly referralAccountTextSent: Date;
  readonly mobile_signin_code?: string;
  readonly mobile_signin_code_issued_at?: Date;
  readonly dsoManager?: IUser;
  readonly rate_limit?: number;
  readonly rate_limit_descend?: number;
  readonly rate_limit_da?: number;
  readonly rate_limit_da_descend?: number;
  readonly rate_limit_dn?: number;
  readonly rate_limit_dn_descend?: number;
  readonly credit_limit: number;
  readonly booking_fee_ratio: number;
  readonly payment_due?: number;
  readonly skills: string[];
  readonly years_of_experience: string;
  readonly school: string;
  readonly graduationDate?: Date;
  readonly isInsuranceAvailable: boolean;
  readonly blocked_at?: Date;
  readonly isAllowedToSeeOfficeName: boolean;
  readonly completedJobs: number;
  readonly is_first_shift_confirmed: boolean;
  readonly parent_office?: IUser;
  readonly restricted_until: Date;
  readonly cancellation_histories: ICancellationHistory[];
  readonly backgroundCheck: IBackgroundCheckInfo;
  readonly favoriteProfessionals: IUser[];
  readonly favoriteProfessionalsHistories: IFavoriteProfessionalHistory[];
  readonly dsoName: string;
  readonly allowedRequest: IAllowedRequest;
  readonly cancellationPolicy: ICancellationPolicy;
  readonly verifiedAt: Date;
  readonly partnerReferral?: IPartnerReferral;
  readonly verificationSessionId: string;
  readonly legalIdentityInformation?: ILegalIdentityInformation;
  readonly medMalSurveyStatus?: MedMalSurveyStatusEnum;
  readonly medMalSurveys?: IMedMalSurvey[];
  readonly medMalInsuranceSurveys?: IMedMalInsuranceSurvey[];

  /**
   * @deprecated - educationUpdatedAt is removed in the future,
   *  use value from {@link IUserCredentialsResponse} instead
   */
  readonly educationUpdatedAt: Date;
  readonly parkingInformation?: IParkingInformation | null;
  readonly isTermsAccepted: boolean;
  readonly workerClassification?: IWorkerClassification | null;
  readonly browserFingerprints: string[];
  readonly verifiedNotification: boolean;
  readonly trinetOfficeId: string;
  readonly defaultReferralType: string;
  readonly workerClassificationRequest: IWorkerClassificationRequest[];
  readonly authenticationMethods: AuthenticationMethod[];
  readonly title: string;

  readonly license: ILicense;
  readonly isFavorite: boolean;
  readonly badges: IBadge[];
  /**
   * @deprecated - behind {@link getShouldUseW9Status} use {@link contractorPopup} instead
   */
  readonly isW9BannerOpen: boolean;
  readonly contractorPopup: W9Status;
  readonly badgeCount?: number;
  readonly isWorkerClassificationEnabled?: boolean;
  readonly is_stripe_tos_accepted?: boolean;
  readonly show_nps?: boolean;
}

interface IBadge {
  type: string;
  level: number;
}

export enum UserTypeEnum {
  HYG = 'HYG',
  DNT = 'DNT',
}

enum UserStatusEnum {
  Verified = 'verified',
  Blocked = 'blocked',
  Paused = 'paused',
  Restricted = 'restricted',
  Removed = 'removed',
}

enum UserOriginEnum {
  App = 'app',
  JobBoard = 'job-board',
  Web = 'web-portal',
}

export enum EmailStatus {
  NotVerified = 'not-verified',
  ExistingUser = 'existing-user',
  Verified = 'verified',
}

export enum AccountActivationStatus {
  ShortAccount = 'ShortAccount',
  IncompleteAccount = 'IncompleteAccount',
  CompleteAccount = 'CompleteAccount',
  NonCompliantAccount = 'NonCompliantAccount',
  ActivatedAccount = 'ActivatedAccount',
  VerifiedAccount = 'VerifiedAccount',
}

export enum AuthenticationMethod {
  phone = 'phone',
  password = 'password',
}

export interface IOvertimeLimits {
  overtimeLimits: {
    contractor?: {
      daily?: number;
      weekly?: number;
    };
    employee?: {
      daily?: number;
      weekly?: number;
    };
  };
}

export interface IEventNotificationSettings {
  email: boolean;
  event: string;
  push: boolean;
  text: boolean;
}

export interface IReferralInfo {
  id: string;
  type: string;
  expiration: string;
  isCancelled: boolean;
  url: string;
  rewardReceiverDNT: number;
  rewardReceiverHYG: number;
  rewardSenderDNT: number;
  rewardSenderHYG: number;
  rewardReceiverWhenSenderDNTReceiverDNT: number;
  rewardReceiverWhenSenderDNTReceiverHYG: number;
  rewardReceiverWhenSenderHYGReceiverDNT: number;
  rewardReceiverWhenSenderHYGReceiverHYG: number;
  rewardSenderWhenSenderDNTReceiverDNT: number;
  rewardSenderWhenSenderDNTReceiverHYG: number;
  rewardSenderWhenSenderHYGReceiverDNT: number;
  rewardSenderWhenSenderHYGReceiverHYG: number;
}
