import React from 'react';
import Checkcircle from '../Checkcircle';

interface IProps {
  text: string | number;
  checked: boolean;
  onClick: () => void;
  textStyle?: React.CSSProperties;
  disabled?: boolean;
  size?: 'sm' | 'md' | 'lg';
  style?: React.CSSProperties;
}

export const RadioOption = ({
  text,
  checked,
  onClick,
  textStyle = {},
  disabled = false,
  size = 'md',
  style = {},
}: IProps) => (
  <button
    type="button"
    style={{
      all: 'unset',
      display: 'flex',
      cursor: disabled ? 'not-allowed' : 'pointer',
      alignItems: 'center',
      ...style,
    }}
    onClick={onClick}
    disabled={disabled}
  >
    <Checkcircle checked={checked} size={size} />

    <p
      className="global_font f-dark"
      style={{
        margin: '0px 0px 0px 12px',
        ...textStyle,
      }}
    >
      {text}
    </p>
  </button>
);
