import { Box, Row, Text } from '~/commonComponents';
import { Colors } from '~/themes/colors';

interface IReferralSummaryProps {
  totalReferrals: number;
  totalEarned: number;
  style?: React.CSSProperties;
}

export const ReferralSummary = ({ totalReferrals, totalEarned, style }: IReferralSummaryProps) => {
  return (
    <Row
      width={'532px'}
      backgroundColor={Colors.neutral_50}
      borderRadius={7}
      padding={10}
      alignItems="center"
      justifyContent="center"
      style={style}
    >
      <Text bold fontSize={24} color="primary_500" marginRight={9}>
        {totalReferrals}
      </Text>
      <Text> Total Referrals </Text>

      <Box
        width={2}
        height="100%"
        backgroundColor={Colors.neutral_100}
        marginLeft={24}
        marginRight={28}
      />

      <Text bold fontSize={24} color="success_400" marginRight={9}>
        ${totalEarned}
      </Text>
      <Text>Earned</Text>
    </Row>
  );
};
