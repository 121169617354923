import { useDispatch, useSelector } from 'react-redux';
import '../../../themes/global.scss';
import '../../../themes/registration.scss';

import { useLocation } from 'react-router';
import { closeModal, editUserAccount, phoneVerification } from '../../../actions';
import { trackEvent } from '../../../api/analytics';
import BackButton from '../../../commonComponents/BackButton';
import MobileAppModal from '../../../commonComponents/MobileAppModal';
import { Colors } from '../../../themes/colors';
import { ContainerLayout, ContentLayout, Title } from '../components';
import { DetailedView } from './DetailedView';
import { useState } from 'react';

export const GeneralOfficeInformation = () => {
  const location = useLocation();
  const isEditing = location?.state?.isEdit || false;
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const pendingPhoneVerification = useSelector((state) => state.user.pendingPhoneVerification);
  const [contactNumber, setContactNumber] = useState();

  return (
    <>
      <ContainerLayout>
        <div style={{ position: 'absolute', left: 60, top: 140 }}>
          <BackButton color={Colors.white} url="/registration" />
        </div>
        <Title text="General Office Information" progress="step2" />
        <ContentLayout>
          <DetailedView
            isEditing={isEditing}
            submitBtnPressed={(data) => {
              dispatch(editUserAccount(data));
              setContactNumber(data.contact_number || user?.contact_number);
            }}
          />
        </ContentLayout>
      </ContainerLayout>
      {pendingPhoneVerification && (
        <MobileAppModal
          show={pendingPhoneVerification}
          closeModal={() => dispatch(closeModal())}
          content="pin"
          number={contactNumber}
          result={(code) => {
            trackEvent('Office Sign up - Phone number OTP submitted', { code });
            dispatch(phoneVerification({ code, phone: contactNumber }));
          }}
        />
      )}
    </>
  );
};
