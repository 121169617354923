import { Box, Center, Column, Pressable, Row, Text } from '~/commonComponents';
import history from '~/history';
import HeartBorderedIcon from '~/images/icons/HeartBorderedIcon';
import { IUser } from '~/models';
import { Colors } from '~/themes/colors';
import { CompanyMatchLabel } from '../CandidateList/components/UserCard/components/CompanyMatchLabel';

// @ts-expect-error - unable to resolve path
import defaultProfile from '~/images/HY_tooth.png';
// @ts-expect-error - unable to resolve path
import RatingIcon from '~/images/star_icon.svg';
// @ts-expect-error - unable to resolve path
import ChevronRight from '~/images/arrow_right.svg';

interface IProps {
  jobId: string;
  candidate?: IUser;
  showLicense?: boolean;
}

export const CandidateProfile = ({ jobId, candidate, showLicense = false }: IProps) => (
  <Box>
    <Row gap={20}>
      <Center
        border={`3px solid ${candidate?.isFavorite ? Colors.favorites_400 : 'transparent'}`}
        borderRadius={94}
        height={94}
        padding={2}
        position="relative"
        width={94}
      >
        {candidate && candidate.profile_image ? (
          <img
            alt="profile_image"
            src={candidate.profile_image}
            style={{
              width: 94,
              height: 94,
              borderRadius: 94,
              objectFit: 'cover',
            }}
          />
        ) : (
          <Box className="default_circle_container">
            <img src={defaultProfile} alt="profile_image" width="94px" />
          </Box>
        )}
        {candidate?.isFavorite && (
          <Box style={{ position: 'absolute', top: '72%', right: -4 }}>
            <HeartBorderedIcon />
          </Box>
        )}
      </Center>
      <Column justifyContent="center">
        <Row
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Text
            bold
            color="neutral_600"
            fontSize={20}
            margin="0px 7px 0px 0px"
            maxWidth={280}
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {`${candidate?.first_name} ${candidate?.last_name}`}
          </Text>

          <Row alignItems="center" gap={5}>
            <img
              src={RatingIcon}
              alt="green"
              style={{
                width: 20,
                height: 20,
                margin: '0px 5px 3px 0px',
              }}
            />
            <Text bold fontSize={16} color="neutral_600">
              {candidate?.average_rating?.toFixed(1) || '5.0'}
            </Text>
          </Row>

          <Box
            alignSelf="center"
            border={`1px solid ${Colors.neutral_600}`}
            height={16}
            marginHorizontal={8}
          />

          <Pressable
            onPress={() => {
              history.push(`/dashboard/candidate/${jobId}`, {
                candidateId: candidate?.id,
              });
            }}
          >
            <Row alignItems="center" cursor="pointer" gap={8}>
              <Text bold color="secondary_500" fontSize={20}>
                Profile
              </Text>
              <img src={ChevronRight} alt="arrow" style={{ width: 16, height: 16 }} />
            </Row>
          </Pressable>
        </Row>

        {showLicense && (
          <>
            <Text color="neutral_600" fontSize={16}>
              Drivers License Verified
            </Text>

            <Text color="neutral_600" fontSize={16}>
              {candidate?.license?.subProfession ?? candidate?.license?.profession} Credentials
              Verified
            </Text>
          </>
        )}

        <CompanyMatchLabel professional={candidate} />
      </Column>
    </Row>
  </Box>
);
