import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '~/hooks/useAppSelector';
import { confirmReferralPayout, hideReferralPayoutPopup } from '~/actions';
import { ConfirmPopup } from '~/commonComponents';

// @ts-expect-error - unable to resolve path
import ReferralEGift from '~/images/referral/referral-reward-e-gift.svg';

export const ReferralRewardPopup = () => {
  const dispatch = useDispatch();
  const { referralPayouts } = useAppSelector((state) => state.user);

  const showReferralRewardPopup = referralPayouts?.length > 0;

  useEffect(() => {
    if (showReferralRewardPopup) {
      referralPayouts?.forEach((payout) => {
        dispatch(confirmReferralPayout(payout.id));
      });
    }
  }, [dispatch, showReferralRewardPopup, referralPayouts]);

  if (!showReferralRewardPopup) {
    return null;
  }

  return (
    // @ts-expect-error - missing props
    <ConfirmPopup
      Icon={() => (
        <img
          src={ReferralEGift}
          alt="e-gift"
          style={{ width: 154, height: 121, marginBottom: 10 }}
        />
      )}
      title={'Your e-gift card is on the way!'}
      description={
        'The friend you referred has successfully completed their first shift, and your e-gift card has been sent to the email address associated with this account. Keep referring more friends to earn more rewards!'
      }
      descriptionStyle={{ marginBottom: 25 }}
      rightButtonText={'Close'}
      rightButtonAction={() => dispatch(hideReferralPayoutPopup())}
    />
  );
};
