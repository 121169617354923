export const routes = {
  login: '/',
  forgotPassword: '/forgotpassword',
  contactInformation: '/registration',
  generalOfficeInformation: '/registration/admin',
  additionalOfficeInformation: '/registration/office-info',
  workerClassification: '/registration/worker-classification',
  dashboardHome: '/',
  accountInformation: '/account/information',
  invoices: '/account/invoices',
  editOpenJob: '/dashboard/job/:jobId/edit',
  permanentJobDetails: '/dashboard/permanentJobs/:jobId',
  permanentJobs: '/dashboard/permanentJobs',
  inbox: '/dashboard/inbox',
  chat: '/dashboard/chat/:jobId',
  expiredCounterOffers: '/dashboard/jobs/:jobId/expired',
  adjustHours: '/dashboard/jobs/:jobId/adjust',
  multiDayShiftRequest: '/dashboard/multi-day/info',
  multiDayShiftEdit: '/dashboard/multi-day/edit',
  multiDayShiftConfirm: '/dashboard/multi-day/confirm',
  permanentHireRequest: '/dashboard/pprs',
  transactionHistoryDetails: '/dashboard/receipts/transactions/:jobId',
  paymentHistory: '/dashboard/receipts',
  todaysJobs: '/dashboard/jobs/today',
  shiftsOverview: '/dashboard/jobs/:type',
  virtualJobRedirection: '/dashboard/job/:jobId/redirect',
  professionalProfile: '/dashboard/candidate/:jobId',
  confirmCandidate: '/dashboard/job/:jobId/confirm/:candidateId',
  jobSummary: '/dashboard/job/:jobId/feedback',
  jobSummaryRating: '/dashboard/job/:jobId/feedback-rating',
  jobDetails: '/dashboard/job/:jobId/details',
  openJobDetails: '/dashboard/job/:jobId',
  npsReview: '/dashboard/nps',
  alerts: '/dashboard/alerts',
  schedule: '/dashboard/calendar',
  paymentInformation: '/account/payment',
  referral: '/dashboard/referral',
  referralDisclaimer: '/dashboard/referral/disclaimer',
  help: '/dashboard/help',
  about: '/dashboard/about',
  notificationSettings: '/dashboard/notifications',
  cancellationFeedback: '/dashboard/feedback/:jobId',
  sendFeedback: '/account/feedback',
  jobAdjustment: '/dashboard/adjustments/:jobId',
  howItWorks: '/dashboard/howitworks',
  locumTenensDentist: '/dashboard/locum-tenens-dentist',
  workerClassificationType: '/account/worker-classification-type',
  workerClassificationManagement: '/account/worker-classification-management',
  professionalsHub: '/professionals-hub/:type',
  inviteProfessional: '/invite-professional',
  officeJobInvites: '/invites',
  account: '/account',
  bulkConfirmCandidateJobs: '/dashboard/candidates/:candidateId/jobs',
  candidateJobDetail: '/dashboard/candidates/:candidateId/jobs/:jobId',
};
