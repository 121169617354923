import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { editUser } from '~/actions';
import { Column, ConfirmPopup, RadioOption, Text } from '~/commonComponents';
import { UserType } from '~/enums/UserType';
import { useAppSelector } from '~/hooks/useAppSelector';
import { shouldShowPrimaryOfficeContactPopup } from '../../growthbook';

export const PrimaryOfficeContactPopup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user.user); // logged user
  const selectedOffice = useAppSelector((state) => state.user.selectedChildOffice);
  const [isStorageEmpty, setIsStorageEmpty] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<'user' | 'account_admin' | 'none' | null>(
    null,
  );
  const isBeforeDate = moment().isBefore('2025-05-01');

  const shouldShowPopup =
    user?.is_profile_complete &&
    user?.user_type === UserType.DNT &&
    !!user?.account_admin?.[0]?.first_name &&
    !!user?.account_admin?.[0]?.last_name &&
    !!user?.contact_number &&
    shouldShowPrimaryOfficeContactPopup() &&
    isBeforeDate &&
    isStorageEmpty &&
    user?.id === selectedOffice?.id; // show only for logged user

  const getStorage = useCallback(() => {
    const showPrimaryOfficeContactPopup = localStorage.getItem(
      `showPrimaryOfficeContactPopup-${user?.id}`,
    );

    if (showPrimaryOfficeContactPopup === null || showPrimaryOfficeContactPopup === 'true') {
      setIsStorageEmpty(true);
    }
  }, [user]);

  const setStorage = useCallback(() => {
    localStorage.setItem(`showPrimaryOfficeContactPopup-${user?.id}`, 'false');
    setIsStorageEmpty(false);
  }, [user]);

  const handleRadioSelect = (value: 'user' | 'account_admin' | 'none') => {
    setSelectedOption(value);
  };

  const handleConfirmClick = () => {
    if (selectedOption === 'account_admin') {
      const data = {
        first_name: user?.account_admin?.[0]?.first_name,
        last_name: user?.account_admin?.[0]?.last_name,
        account_admin_first_name: user?.first_name,
        account_admin_last_name: user?.last_name,
      };
      dispatch(editUser({ data }));
    } else if (selectedOption === 'none') {
      navigate('/account/information');
    }
    setStorage();
  };

  useEffect(() => {
    getStorage();
  }, [getStorage]);

  if (!shouldShowPopup) {
    return null;
  }

  return (
    // @ts-expect-error -- missing fields
    <ConfirmPopup
      title="Primary Office Contact"
      rightButtonText="Confirm"
      closePopup={() => setIsStorageEmpty(false)}
      rightButtonAction={handleConfirmClick}
      rightButtonDisabled={!selectedOption}
    >
      <Column gap={32} paddingHorizontal={20} marginBottom={40}>
        <Text textAlign="center">
          In an effort to improve our service, could you please help us verify which user is the
          primary office-level staff associated with this office and the contact information below:
        </Text>

        <Column marginLeft={48} gap={16}>
          <Text>
            <Text bold>Email:</Text> {user?.email_id}
          </Text>
          <Text>
            <Text bold>Mobile:</Text> {user?.contact_number}
          </Text>
        </Column>

        <Column gap={20} marginLeft={40}>
          <RadioOption
            text={`${user?.first_name} ${user?.last_name}`}
            checked={selectedOption === 'user'}
            onClick={() => handleRadioSelect('user')}
          />
          <RadioOption
            text={`${user?.account_admin?.[0]?.first_name} ${user?.account_admin?.[0]?.last_name}`}
            checked={selectedOption === 'account_admin'}
            onClick={() => handleRadioSelect('account_admin')}
          />
          <RadioOption
            text="None of the above"
            checked={selectedOption === 'none'}
            onClick={() => handleRadioSelect('none')}
          />
        </Column>

        <Text textAlign="center" marginHorizontal={56}>
          If the contacts listed above are incorrect, you can easily update this information by
          going to Account Information {'>'} Office Profile.
        </Text>
      </Column>
    </ConfirmPopup>
  );
};
