export interface IWorkerClassification {
  readonly classificationType: WorkerClassificationTypeEnum;
  readonly setPerformedBy: string;
  readonly setPerformedAt: Date;
}

export enum WorkerClassificationTypeEnum {
  Contractor = 'contractor',
  Employee = 'employee',
}
