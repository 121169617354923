export interface IReferral {
  readonly id: string;
  readonly userId: string;
  readonly referredName: string;
  readonly referralStatus: ReferralListStatuses;
  readonly referredUserType: string;
}

export enum ReferralListStatuses {
  AccountCreated = 'Account Created',
  FirstShiftScheduled = 'First Shift Scheduled',
  FirstShiftCompleted = 'First Shift Completed',
  PaymentSent = 'Payment Sent',
}
