import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FORGOT_PASSWORD_RESET_ACTION,
  FORGOT_PASSWORD_SEND_CODE_ACTION,
  forgotPasswordReset,
  forgotPasswordSendCode,
} from '../../../../actions';
import { createLoadingSelector } from '../../../../api/selectors';
import Button from '../../../../commonComponents/Button';
import { InputPassword } from '../../../../commonComponents/InputPassword';
import { VerificationInput } from '../../../../commonComponents/VerificationInput';
import { Colors } from '../../../../themes/colors';
import InputError from '../../components/InputError';
import { ContentLayout } from '../ContentLayout';

export default () => {
  const dispatch = useDispatch();
  const loadingSendCodeSelector = createLoadingSelector([FORGOT_PASSWORD_SEND_CODE_ACTION]);
  const loadingResetSelector = createLoadingSelector([FORGOT_PASSWORD_RESET_ACTION]);
  const isLoadingSendCode = useSelector((state) => loadingSendCodeSelector(state));
  const isLoadingReset = useSelector((state) => loadingResetSelector(state));
  const forgotPasswordFlow = useSelector((state) => state.auth.forgotPasswordFlow);

  const [formValues, setFormValues] = useState({
    newPassword: '',
    confirmPassword: '',
    code: '',
  });
  const [submitError, setSubmitError] = useState(false);

  const showPasswordError = formValues.newPassword.length > 0 && formValues.newPassword.length < 8;
  const showConfirmPasswordError =
    !showPasswordError &&
    formValues.confirmPassword !== formValues.newPassword &&
    formValues.confirmPassword.length > 0;

  const isButtonDisabled = !(
    formValues.newPassword &&
    formValues.confirmPassword &&
    formValues.code.length === 5
  );

  const handleSetFormValue = (value, key) => {
    setFormValues({ ...formValues, [key]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (showPasswordError || showConfirmPasswordError) {
      setSubmitError(true);
      return;
    }

    if (!isButtonDisabled) {
      dispatch(
        forgotPasswordReset({
          code: formValues.code,
          password: formValues.newPassword,
          identifier: forgotPasswordFlow.value,
        }),
      );
    }
  };

  const handleResendCode = (e) => {
    e.preventDefault();
    dispatch(
      forgotPasswordSendCode({
        value: forgotPasswordFlow.value,
        method: forgotPasswordFlow.method,
      }),
    );
  };

  useEffect(() => {
    setSubmitError(false);
  }, [formValues]);

  return (
    <ContentLayout>
      <h1 style={{ fontFamily: 'Nunito', color: Colors.neutral_600, marginBottom: 20 }}>
        Reset Password
      </h1>
      <form
        id="resetPasswordForm"
        style={{
          width: '100%',
          padding: 0,
          margin: 0,
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        onSubmit={handleSubmit}
      >
        <div style={{ marginBottom: 80 }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginBottom: 48,
            }}
          >
            <span
              style={{
                marginBottom: 20,
                fontFamily: 'Nunito',
                color: Colors.neutral_500,
                fontWeight: 400,
                marginTop: 20,
                fontSize: 16,
              }}
            >
              Enter the 5 digit confirmation code sent to {forgotPasswordFlow.value}.
            </span>
            <VerificationInput onChange={(value) => handleSetFormValue(value, 'code')} />
            <div style={{ marginTop: 20 }}>
              <Button
                id="resendCodeButton"
                isLoading={isLoadingSendCode}
                loadingColor={Colors.primary_500}
                text={
                  <h4
                    style={{
                      fontFamily: 'Nunito',
                      color: Colors.secondary_500,
                      margin: 0,
                      fontWeight: 700,
                      fontSize: 16,
                    }}
                  >
                    Resend code
                  </h4>
                }
                type="button"
                style={{ all: 'unset', cursor: 'pointer' }}
                onClick={handleResendCode}
              />
            </div>
          </div>
          <div>
            <p className="input_names" style={{ width: '100%', marginBottom: 8 }}>
              New Password
            </p>
            <InputPassword
              id="newPassword"
              style={{ width: '432px' }}
              placeholder="Enter new password"
              onChange={(e) => handleSetFormValue(e.target.value, 'newPassword')}
            />
            <InputError
              text="Password must contain 8 characters."
              showError={submitError && showPasswordError}
            />
          </div>
          <div style={{ marginTop: 40 }}>
            <p className="input_names" style={{ width: '100%', marginBottom: 8 }}>
              Confirm New Password
            </p>
            <InputPassword
              id="confirmNewPassword"
              style={{ width: '432px' }}
              placeholder="Re-enter new password"
              onChange={(e) => handleSetFormValue(e.target.value, 'confirmPassword')}
            />
            <InputError
              text="Passwords do not match. Please try again."
              showError={submitError && showConfirmPasswordError}
            />
          </div>
        </div>
        <Button
          disabled={isButtonDisabled}
          type="submit"
          text="Reset Password"
          isLoading={isLoadingReset}
        />
      </form>
    </ContentLayout>
  );
};
