import { Center, Text } from '~/commonComponents';

interface IInstructionItemProps {
  icon: string;
  title: string;
  description: string;
}

export const InstructionItem = ({ icon, title, description }: IInstructionItemProps) => {
  return (
    <Center>
      <img src={icon} style={{ width: 37, height: 37 }} alt="icon" />
      <Text bold fontSize={18} color="primary_500" paddingTop={14} textAlign="center">
        {title}
      </Text>
      <Text paddingTop={4} textAlign="center">
        {description}
      </Text>
    </Center>
  );
};
