import { useParams } from 'react-router';
import { ClipLoader } from 'react-spinners';
import { trackEvent } from '~/api/analytics';
import { Box, Button, Center, Column, ConfirmPopup, Row, Text } from '~/commonComponents';
import browserHistory from '~/history';
import { CircularLoading } from '~/images/lottie';
import { Colors } from '~/themes/colors';
import { CandidateProfile } from '../candidates';
import { ShiftList } from './components';
import { useBulkConfirmCandidates } from './hooks/useBulkConfirmCandidates';

// @ts-expect-error -- its image file
import ConfirmCircle from '~/images/confirm-circle.svg';

export const BulkConfirmCandidateJobs = () => {
  const params = useParams<{ candidateId: string }>();

  const {
    checkedJobs,
    isContractor,
    isEmployee,
    isFirstLoading,
    isLoadingJobsConfirmation,
    isJobsConfirmed,
    jobs,
    handleClosePopup,
    handleConfirm,
    handleToggleOption,
  } = useBulkConfirmCandidates(params.candidateId || '');

  const totalCheckedJobs = Object.keys(checkedJobs).length;
  const hasSelection = totalCheckedJobs > 0;

  return (
    <>
      <Box className="component_container" justifyContent="flex-start">
        <Column className="floating_container" paddingVertical={36} width="auto">
          <Text bold textAlign="center" fontSize={32} color="neutral_600" marginBottom={24}>
            Confirm Shifts
          </Text>
          {isFirstLoading ? (
            <Center>
              <ClipLoader size={32} color={Colors.primary_500} loading />
            </Center>
          ) : (
            <>
              <Row paddingHorizontal={40} alignItems="center" justifyContent="space-between">
                <CandidateProfile candidate={jobs?.[0]?.candidate} jobId={jobs?.[0]?.id} />
                <Row gap={12}>
                  <Button
                    text="Not Now"
                    style={{
                      width: 152,
                      height: 48,
                      backgroundColor: Colors.secondary_500,
                      marginTop: 0,
                    }}
                    onClick={() => {
                      trackEvent('Confirm Shifts - Not now Clicked');

                      browserHistory.push('/dashboard/jobs/pending');
                    }}
                  />
                  <Button
                    disabled={!hasSelection}
                    isLoading={isLoadingJobsConfirmation}
                    text={
                      totalCheckedJobs > 0 && isContractor
                        ? `Confirm (${totalCheckedJobs})`
                        : 'Confirm'
                    }
                    style={{
                      width: 152,
                      height: 48,
                      marginTop: 0,
                    }}
                    onClick={handleConfirm}
                  />
                </Row>
              </Row>
              <Center paddingHorizontal={114} marginVertical={32}>
                <Text color="neutral_500" fontSize={16} fontStyle="italic" textAlign="center">
                  The list below displays additional offers that {jobs?.[0]?.candidate?.first_name}{' '}
                  {jobs?.[0]?.candidate?.last_name} has submitted for other shifts at your office.
                  Review the candidate&apos;s application details, including any counters they
                  submitted for the hourly rate and/or shift hours.
                </Text>
              </Center>
              <ShiftList
                checkedJobs={checkedJobs}
                isMultipleSelection={isContractor && !isEmployee}
                jobs={jobs}
                handleToggleOption={handleToggleOption}
              />
            </>
          )}
        </Column>
      </Box>
      {isLoadingJobsConfirmation && (
        // @ts-expect-error -- ConfirmPopup still written in Javascript
        <ConfirmPopup
          Icon={() => (
            <Center>
              <CircularLoading />
            </Center>
          )}
          title="Processing..."
          titleStyle={{ marginTop: 36, marginBottom: 8 }}
          description="Please, wait while we confirm your selected shifts. Thank you for your patience."
          descriptionStyle={{ color: Colors.neutral_500, marginBottom: 0 }}
          modalStyle={{
            paddingTop: 64,
            paddingBottom: 64,
          }}
        />
      )}
      {isJobsConfirmed && (
        // @ts-expect-error -- ConfirmPopup still written in Javascript
        <ConfirmPopup
          Icon={() => (
            <Center>
              <img alt="confirm_circle" src={ConfirmCircle} />
            </Center>
          )}
          title="Good News!"
          titleStyle={{ marginTop: 28, marginBottom: 8 }}
          description={
            isEmployee
              ? 'Your shift has successfully been filled.'
              : // eslint-disable-next-line max-len
                `${jobs?.[0]?.candidate?.first_name} ${jobs?.[0]?.candidate?.last_name} has been confirmed for ${Object.keys(checkedJobs).length} shift(s).`
          }
          descriptionStyle={{ color: Colors.neutral_500, marginBottom: 40 }}
          modalStyle={{
            paddingTop: 64,
            paddingBottom: 64,
          }}
          rightButtonText="Ok"
          rightButtonAction={handleClosePopup}
        />
      )}
    </>
  );
};
