import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  SELECT_DEFAULT_INFO_MDB,
  editJobInfoMdb,
  getAllProfessionals,
} from '../../../../../actions';
import { createLoadingSelector } from '../../../../../api/selectors';
import CardSpinner from '../../../../../commonComponents/CardSpinner';
import { getAutoFillInfo } from '../../../../../constants';
import history from '../../../../../history';

import BackButton from '../../../../../commonComponents/BackButton';
import Button from '../../../../../commonComponents/Button';
import { UserProfession, UserSubProfession } from '../../../../../enums/UserProfession';
import { getShouldOfficeShowEfda } from '../../../../../growthbook';
import { getAllProfessionTypeOptions } from '../../../../../utils/DentistUtils';
import { EnumHelper } from '../../../../../utils/EnumHelper';
import Form, { FORM_FIELDS } from '../../components/Form';
import ProgressBar from '../../components/ProgressBar';
import { useNavigate } from 'react-router';

export const EditShiftContent = () => {
  const navigate = useNavigate();
  const editedDate = history.location?.state?.editedDate || '';
  const currentJob = useSelector(
    (state) => state.multiday.jobInfo.filter((job) => job.localDate === editedDate)[0],
  );
  const initialBreak = currentJob?.break_time === 0 ? '00' : currentJob?.break_time;
  const defaultOpenCardIndex = history.location?.state?.defaultOpenCardIndex;
  const allProfessions = useSelector((state) => state.user.allProfessions);
  const showEfda = getShouldOfficeShowEfda();
  const allProfessionOptions = getAllProfessionTypeOptions(allProfessions, { showEfda });

  const loadingSelector = createLoadingSelector([SELECT_DEFAULT_INFO_MDB]);
  const isLoading = useSelector((state) => loadingSelector(state));
  const dispatch = useDispatch();

  const [formValues, setFormValues] = useState({
    offerStartTime: currentJob?.localStart || null,
    offerEndTime: currentJob?.localEnd || null,
    rate: currentJob?.rate || 0,
    lunch: initialBreak || null,
    isLunchPaid: currentJob?.is_break_paid || false,
    professionType:
      UserSubProfession.getName(currentJob?.subProfession) ??
      UserProfession.getName(currentJob?.profession),
    skills: currentJob?.specialty || 'specialty_General Dentistry',
    procedures: currentJob?.procedures ?? [],
    enableAutoFill: currentJob?.autoFill || false,
    enableAutoFillFavorites: currentJob?.autoFillFavorites || false,
    enableAutoFillHighlyRated: currentJob?.autoFillHighlyRated || false,
    isAssistedHygiene: currentJob?.isAssistedHygiene,
    professional: currentJob?.professional,
  });

  const selectedProfessionCode = allProfessionOptions?.filter(
    (item) => item.name === formValues.professionType,
  )?.[0]?.code;
  const autoFillInfo = useMemo(
    () => getAutoFillInfo(formValues?.professionType, formValues?.skills),
    [formValues?.professionType, formValues?.skills],
  );

  const isDisabled =
    !formValues.offerStartTime ||
    !formValues.offerEndTime ||
    formValues.rate === 0 ||
    !formValues.lunch;

  useEffect(() => {
    if (!defaultOpenCardIndex) {
      window.scrollTo(0, 0);
    }

    if (!allProfessions || allProfessions?.length === 0) {
      dispatch(getAllProfessionals());
    }
  }, []);

  const handleSubmit = () => {
    const jobData = {
      localStart: formValues.offerStartTime,
      localEnd: formValues.offerEndTime,
      rate: formValues.rate,
      is_break_paid: formValues.isLunchPaid,
      break_time: formValues.lunch,
      // use profession. normalized if sub-profession is included
      profession: UserProfession.getNormalizedCode(selectedProfessionCode),

      // include sub-profession if it's a member of UserSubProfession
      subProfession: EnumHelper.getObjectKeys(UserSubProfession).includes(selectedProfessionCode)
        ? selectedProfessionCode
        : null,
      specialty: formValues.skills || 'specialty_General Dentistry',
      autoFill: autoFillInfo?.show ? formValues.enableAutoFill : false,
      autoFillFavorites: autoFillInfo?.show ? formValues.enableAutoFillFavorites : false,
      autoFillHighlyRated: autoFillInfo?.show ? formValues.enableAutoFillHighlyRated : false,
      procedures: formValues.procedures,
      isAssistedHygiene: formValues.isAssistedHygiene,
      professional: formValues.professional,
    };

    dispatch(editJobInfoMdb(currentJob.localDate, jobData));
    navigate(-1);
  };

  if (isLoading) {
    return <CardSpinner />;
  }

  return (
    <div className="component_container">
      <div
        className="floating_container"
        style={{ justifyContent: 'flex-start', flexDirection: 'column', paddingBottom: 120 }}
      >
        <div className="card">
          <div style={{ marginTop: 20, marginLeft: 30 }}>
            <BackButton />
          </div>
          <div className="card-body">
            <ProgressBar step={2} title="Edit Shift" isHidden />
            <Form
              job={currentJob}
              formValues={formValues}
              setFormValues={setFormValues}
              defaultOpenCardIndex={defaultOpenCardIndex}
              editDate
              disabledFields={[FORM_FIELDS.DATES, FORM_FIELDS.PROFESSIONAL]}
            />
          </div>
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: 20,
            marginTop: 20,
          }}
        >
          <Button
            text="Save Changes"
            onClick={handleSubmit}
            disabled={isDisabled}
            data-testid="save-changes-button"
          />
        </div>
      </div>
    </div>
  );
};
