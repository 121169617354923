import { Colors } from '../themes/colors';
import arrowBack from '../images/Arrow_left.png';
import arrowBackWhite from '../images/arrow_left.svg';
import history from '../history';
import { useNavigate } from 'react-router';

export default function BackWhiteButton({ whiteArrow, containerStyle, backRoute }) {
  const navigate = useNavigate();

  return (
    <div
      style={{
        display: 'flex',
        cursor: 'pointer',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        marginLeft: 10,
        ...containerStyle,
      }}
      onClick={() => (backRoute ? history.push(backRoute) : navigate(-1))}
    >
      <img
        src={whiteArrow ? arrowBackWhite : arrowBack}
        alt="back"
        style={{ height: 20, marginRight: 10 }}
      />
      <p
        className="global_font f-dark"
        style={{
          color: Colors.white,
          fontWeight: 'bold',
          fontSize: 22,
          textAlign: 'left',
          width: '100%',
          cursor: 'pointer',
        }}
      >
        Back
      </p>
    </div>
  );
}
