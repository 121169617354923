import { Box } from '~/commonComponents';
import { EmptyScreen } from '~/commonComponents/EmptyScreen/EmptyScreen';

// @ts-expect-error - unable to resolve path
import EmptyReferralIcon from '~/images/referral/referral-empty.svg';

export const EmptyReferral = () => {
  return (
    <Box>
      <EmptyScreen
        Icon={() => <img src={EmptyReferralIcon} alt="icon" style={{ width: 172, height: 171 }} />}
        title={'No referrals yet!'}
        description={
          'Start inviting your friends above! Once your invites are on the way, you’ll see their status listed down here.'
        }
      />
    </Box>
  );
};
