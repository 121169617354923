import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router';
import { routes } from '~/routes';
import { fetchNotificationSettings, logOut, LOGOUT_ACTION } from '../../actions';
import { createLoadingSelector } from '../../api/selectors';
import { Icon, Row } from '../../commonComponents';
import CardSpinner from '../../commonComponents/CardSpinner';
import { Colors } from '../../themes/colors';
import '../../themes/global.scss';
import { RemoteConfigUtils } from '../../utils/RemoteConfigUtils';
import { useAccountInformation } from '../AccountInformation/hooks/useAccountInformation/useAccountInformation';
import { usePaymentExpiration } from '../Inbox/Notification/Reminders/hooks/usePaymentExpiration';
import { useNotificationTypeMap } from '../NotificationSettings/hooks/useNotificationSettings';
import ConfirmPopup from '../shared/ConfirmPopup';
import ProfileHeaderCard from './ProfileHeaderCard';

export default function Profile() {
  const dispatch = useDispatch();
  const allowedWcStates = RemoteConfigUtils.getAllowedWcStates();
  const loadingSelector = createLoadingSelector([LOGOUT_ACTION]);
  const [showLogoutPopup, setShowLogoutPopup] = useState();
  const { shouldShowWarning } = useNotificationTypeMap();
  const childOffices = useSelector((state) => state.user.childOffices);
  const user = useSelector((state) => state.user.selectedChildOffice);
  const notificationSettings = useSelector((state) => state.user.notificationSettings);
  const showNotificationWarning = notificationSettings?.some(({ event, ...settings }) =>
    shouldShowWarning(event, settings),
  );
  const { isValid: isAccountInformationValid } = useAccountInformation();
  const { isPaymentExpired, isPaymentExpiring } = usePaymentExpiration();

  const isLoading = useSelector((state) => loadingSelector(state));

  const shouldBeDisplayedForParent = childOffices?.find((office) =>
    allowedWcStates.includes(office.address?.[0]?.state),
  );

  useEffect(() => {
    dispatch(fetchNotificationSettings());
  }, [dispatch]);

  if (isLoading) {
    return <CardSpinner />;
  }

  return (
    <div className="component_container">
      <div
        className="floating_container"
        style={{
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          padding: '40px 80px',
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
          }}
        >
          <h1 className="global_font f-dark" style={{ alignSelf: 'center' }}>
            Account
          </h1>
          <ProfileHeaderCard />

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              marginTop: 20,
              marginLeft: 20,
            }}
          >
            <Link to="/account/information" style={{ fontSize: 18 }} className="link">
              <Row gap={4} alignItems="center">
                Account Information
                {!isAccountInformationValid && (
                  <Icon name="warning-solid" color={Colors.warning_500} />
                )}
              </Row>
            </Link>

            <div className="line" style={{ width: '95%', margin: 0 }} />
            <Link to="/account/payment" style={{ fontSize: 18 }} className="link">
              <Row gap={4} alignItems="center">
                Payment Information
                {(isPaymentExpired || isPaymentExpiring) && (
                  <Icon
                    name="warning-solid"
                    color={
                      !isPaymentExpired && isPaymentExpiring ? Colors.warning_500 : Colors.error_500
                    }
                  />
                )}
              </Row>
            </Link>

            <div className="line" style={{ width: '95%', margin: 0 }} />
            <Link to="/dashboard/receipts" style={{ fontSize: 18 }} className="link">
              Payment History
            </Link>

            {user.is_pre_approved && (
              <>
                <div className="line" style={{ width: '95%', margin: 0 }} />
                <Link to="/account/invoices" style={{ fontSize: 18 }} className="link">
                  Invoices
                </Link>
              </>
            )}

            <div className="line" style={{ width: '95%', margin: 0 }} />
            <Link to="/dashboard/notifications" className="link" style={{ fontSize: 18 }}>
              <Row gap={4} alignItems="center">
                Notification Settings
                {showNotificationWarning && <Icon name="warning-solid" color={Colors.error_500} />}
              </Row>
            </Link>

            {user?.isWorkerClassificationEnabled && (
              <>
                <div className="line" style={{ width: '95%', margin: 0 }} />
                <Link
                  to={routes.workerClassificationType}
                  className="link"
                  style={{ fontSize: 18, display: 'flex' }}
                >
                  <Row gap={4} alignItems="center">
                    Worker Type
                    {!user?.workerClassification?.classificationType && (
                      <Icon name="warning-solid" color={Colors.error_500} />
                    )}
                  </Row>
                </Link>
              </>
            )}

            {childOffices.length > 0 && shouldBeDisplayedForParent && (
              <>
                <div className="line" style={{ width: '95%', margin: 0 }} />
                <Link
                  to="/account/worker-classification-management"
                  className="link"
                  style={{ display: 'flex', fontSize: 18 }}
                >
                  Worker Type Management
                </Link>
              </>
            )}

            <div className="line" style={{ width: '95%', margin: 0 }} />
            <Link to="/account/feedback" style={{ fontSize: 18 }} className="link">
              Send Feedback
            </Link>

            <div className="line" style={{ width: '95%', margin: 0 }} />
            <Link to="/dashboard/about" style={{ fontSize: 18 }} className="link">
              About
            </Link>

            <div className="line" style={{ width: '95%', margin: 0 }} />
            <p
              to="/account"
              className="link"
              style={{ margin: 0, cursor: 'pointer', fontSize: 18 }}
              onClick={() => setShowLogoutPopup(true)}
            >
              Log Out
            </p>
          </div>
        </div>
      </div>

      {showLogoutPopup && (
        <ConfirmPopup
          title="Confirm Log Out"
          description="Are you sure you would like to log out?"
          leftButtonText="Go Back"
          leftButtonAction={() => setShowLogoutPopup(false)}
          rightButtonText="Log Out"
          rightButtonAction={() => {
            sessionStorage.clear();
            dispatch(logOut());
            setShowLogoutPopup(false);
          }}
        />
      )}
    </div>
  );
}
