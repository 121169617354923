import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import ConfirmPopup from '../../../components/shared/ConfirmPopup';
import { shouldDisplayPopupNjEmployeeLaunch } from '../../../growthbook';
import SelectWorkerType from '../../../images/SelectWorkerType.svg';
import { Colors } from '../../../themes/colors';
import { routes } from '~/routes';

export function SelectWorkerTypePopup() {
  const user = useSelector((state) => state.user.selectedChildOffice ?? state.user.user);
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();

  const getStorage = () => {
    const selectWorkerTypePopup = localStorage.getItem(`selectWorkerTypePopup-${user?.id}`);

    if (selectWorkerTypePopup === null) {
      setShowPopup(true);
    }
  };

  const setStorage = () => {
    localStorage.setItem(`selectWorkerTypePopup-${user?.id}`, 'false');
    setShowPopup(false);
  };

  const handleConfirm = () => {
    navigate(routes.workerClassificationType);
    setStorage();
  };

  useEffect(() => {
    getStorage();
  }, [user]);

  return (
    showPopup &&
    shouldDisplayPopupNjEmployeeLaunch() && (
      <ConfirmPopup
        Icon={() => (
          <img
            alt="SelectWorkerType"
            src={SelectWorkerType}
            style={{ width: 224, height: 158, marginBottom: 24 }}
          />
        )}
        title="Select a Worker Type!"
        description={
          <div
            style={{
              textAlign: 'left',
            }}
          >
            Before you can continue posting new shifts on GoTu, you will need to select a worker
            type for your office.
            <br />
            <br />
            <span>What does this mean for your existing shifts?</span>
            <ul
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 8,
              }}
            >
              <li>Any filled shifts will be completed with contractors.</li>
              <li>
                Any unfilled shifts will be removed and will need to be reposted after a worker type
                is selected.
              </li>
            </ul>
            <span>Click “Learn More” for more information on your options.</span>
          </div>
        }
        descriptionStyle={{ color: Colors.neutral_500, marginBottom: 32, width: 455 }}
        rightButtonText="Learn More"
        rightButtonAction={handleConfirm}
      />
    )
  );
}
