import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getReferralPayouts } from '~/actions';
import { getShouldEnableReferralProgramV2 } from '~/growthbook';
import { useUserSelector } from '~/hooks/useUserSelector';

const requestInterval = 1 * 60 * 60 * 1000; // 1 hour in milliseconds

export const useReferralPayout = () => {
  const dispatch = useDispatch();
  const user = useUserSelector();
  const userId = user?.id;

  const shouldRequestPayout = useCallback(() => {
    if (!userId || !getShouldEnableReferralProgramV2()) return false;

    try {
      const prevRequestTime = localStorage.getItem(`prevReferralPayoutReqDate-${userId}`);
      return !prevRequestTime || Date.now() - new Date(prevRequestTime).getTime() > requestInterval;
    } catch {
      return false;
    }
  }, [userId]);

  useEffect(() => {
    if (shouldRequestPayout()) {
      dispatch(getReferralPayouts());
      localStorage.setItem(`prevReferralPayoutReqDate-${userId}`, new Date().toISOString());
    }
  }, [dispatch, shouldRequestPayout, userId]);
};
