import { load } from '@fingerprintjs/fingerprintjs';

/**
 * Retrieves common headers related to device and user agent information.
 */
export const getCommonHeaders = async (): Promise<Record<string, string>> => {
  /* Device Type */
  const deviceType = 'Web';
  /* Device Type */
  const appType = 'dental_portal';
  /* User Agent */
  const userAgent = navigator.userAgent;

  /* Device Fingerprint */
  let deviceFingerprint = null;
  try {
    const fingerprint = await load();
    const { visitorId } = await fingerprint.get();
    deviceFingerprint = visitorId;
  } catch (error) {
    console.error('Failed to load device fingerprint:', error);
  }

  return {
    'x-device-type': deviceType,
    'x-user-agent': userAgent,
    'x-app-type': appType,
    'x-device-fingerprint': deviceFingerprint || '',
  };
};
