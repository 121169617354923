import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllProfessionals } from '~/actions';
import { Row } from '~/commonComponents';
import { UserProfession } from '~/enums/UserProfession';
import { getShouldAllowOfficeToPostProcedure, getShouldOfficeShowEfda } from '~/growthbook';
import { Colors } from '~/themes/colors';
import { getAllProfessionTypeOptions } from '~/utils/DentistUtils';
import { EducationUtils } from '~/utils/EducationUtils';
import briefcaseIcon from '../../../../images/briefcase.svg';
import calendarIcon from '../../../../images/calendar-icon.svg';
import MailIcon from '../../../../images/mail-solid.svg';
import ToothIcon from '../../../../images/tooth-icon.svg';
import userIcon from '../../../../images/user-icon.svg';
import Tooltip from '../../../Tooltip';
import { workerClassificationTooltipDescription } from '../../constants';
import { ProcedureCollapsibleSelection } from '../../temp/ProcedureCollapsibleSelection';
import { Title } from './CollapsibleRow/Title';
import { Value } from './CollapsibleRow/Value';
import { AssistedHygieneAvailableRow } from './Rows/AssistedHygieneAvailableRow';
import { AutoConfirmRow } from './Rows/AutoConfirmRow';
import { BreakRow } from './Rows/BreakRow';
import { DateRow } from './Rows/DateRow/DateRow';
import { SingleDateRow } from './Rows/DateRow/SingleDateRow';
import { HourlyRateRow } from './Rows/HourlyRateRow';
import { HoursRow } from './Rows/HoursRow';
import { InviteRow } from './Rows/InviteRow';
import { OfficeRow } from './Rows/OfficeRow';
import { ProfessionalRow } from './Rows/ProfessionalRow';
import { SkillsRow } from './Rows/SkillsRow';

export const FORM_FIELDS = {
  DATES: 'dates',
  PROFESSIONAL: 'professional',
  SKILLS: 'skills',
  IS_ASSISTED_HYGIENE: 'isAssistedHygiene',
  PROCEDURES: 'procedures',
  HOURS: 'hours',
  BREAK: 'break',
  RATE: 'rate',
  AUTO_CONFIRM: 'autoConfirm',
  INVITE: 'invite',
};

export default function Form({
  job,
  formValues,
  setFormValues,
  defaultOpenCardIndex = null,
  disabledFields = [],
  editDate = false,
}) {
  const user = useSelector((state) => state.user.selectedChildOffice);
  const parentUser = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const {
    lunch,
    professionType,
    skills,
    enableAutoFill,
    enableAutoFillFavorites,
    enableAutoFillHighlyRated,
    rate,
    isLunchPaid,
    offerEndTime,
    procedures,
    offerStartTime,
    isAssistedHygiene,
    professional,
  } = formValues;

  // original profession type code, including sub-profession
  const professionTypeCode = UserProfession.getCode(professionType);

  const allProfessions = useSelector((state) => state.user.allProfessions);
  const showEfda = getShouldOfficeShowEfda();
  const allProfessionOptions = getAllProfessionTypeOptions(allProfessions, { showEfda });

  const userEducation = useSelector((state) => state.user.userEducation);

  const normalizedProfessionCode = UserProfession.getNormalizedCode(professionTypeCode);
  const specialtiesAndProcedures =
    userEducation?.[normalizedProfessionCode]?.specialtiesAndProcedures;

  const averageRating = useSelector((state) => state.job.averageRating) || {
    average: 49,
    max: 100,
    min: 18,
  };

  const availableProcedures = useMemo(
    () =>
      EducationUtils.getProceduresOfProfession({
        specialtiesAndProcedures,
        profession: UserProfession.getNormalizedCode(professionTypeCode),
        specialties: [skills],
      }),
    [skills, professionTypeCode, specialtiesAndProcedures],
  );

  const [selectedProcedureIndices, setSelectedProcedureIndices] = useState([]);

  const profRate = averageRating[professionTypeCode];

  const [openCardIndex, setOpenCardIndex] = useState(defaultOpenCardIndex);
  const rateRef = useRef(null);

  const handleCardToggle = (index) => {
    setOpenCardIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const isFieldDisabled = (field) => disabledFields.includes(field);
  const isProfessionalEditable = !disabledFields.includes(FORM_FIELDS.PROFESSIONAL);

  useEffect(() => {
    if (defaultOpenCardIndex === 'rate') {
      rateRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [defaultOpenCardIndex]);

  // updates selected procedures when available procedures are available
  // or not empty
  //
  // this, to handle `userEducation` might still load when editing occurs
  useEffect(() => {
    if (
      availableProcedures &&
      availableProcedures.length > 0 &&
      procedures &&
      procedures.length > 0
    ) {
      const defaultProcedureIndices = procedures
        .map((procedure) =>
          availableProcedures.findIndex((currentProcedure) => procedure === currentProcedure),
        )
        .filter((procedureIndex) => procedureIndex > -1);

      setSelectedProcedureIndices(defaultProcedureIndices);
    }
  }, [availableProcedures, procedures]);

  useEffect(() => {
    dispatch(getAllProfessionals());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isProfessionalEditable) {
      setSelectedProcedureIndices([]);
    }
  }, [isProfessionalEditable, professionType, skills]);

  const renderJobField = ({ title, value, icon }) => (
    <Row
      style={{
        justifyContent: 'space-between',
        alignItems: 'center',
        border: `1.5px solid ${Colors.neutral_100}`,
        borderRadius: 8,
        width: '60%',
        cursor: 'default',
      }}
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '20px 52px 20px 32px',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img alt="icon" src={icon} style={{ width: 24, height: 24, marginRight: 8 }} />
          <Title bold fontSize={18} text={title} />
        </div>

        <Value text={value} />
      </div>
    </Row>
  );

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          gap: 8,
        }}
      >
        <OfficeRow value={user?.office_name} />

        {job &&
          formValues.professional?.id &&
          renderJobField({
            title: 'Invite:',
            value: job?.professional?.name,
            icon: MailIcon,
          })}

        {parentUser.childOffices.length > 0 &&
          user.isWorkerClassificationEnabled &&
          user.workerClassification &&
          renderJobField({
            title: (
              <span
                style={{
                  marginRight: 16,
                }}
              >
                Worker Type:
                <span
                  style={{
                    marginLeft: '5px',
                    position: 'relative',
                    top: '5px',
                  }}
                >
                  <Tooltip content={workerClassificationTooltipDescription} />
                </span>
              </span>
            ),
            value:
              user.workerClassification.classificationType === 'contractor'
                ? 'Contracted'
                : 'Employed',
            icon: briefcaseIcon,
          })}

        {editDate &&
          isFieldDisabled(FORM_FIELDS.DATES) &&
          renderJobField({
            title: 'Date:',
            value: moment(job?.localDate).format('ddd, MMM DD, YYYY'),
            icon: calendarIcon,
          })}

        {editDate && !isFieldDisabled(FORM_FIELDS.DATES) && (
          <SingleDateRow
            defaultValue={formValues?.localDate}
            isOpen={openCardIndex === FORM_FIELDS.DATES}
            onToggle={() => handleCardToggle(FORM_FIELDS.DATES)}
            onConfirm={(date) => {
              setFormValues((prevState) => ({
                ...prevState,
                localDate: date,
              }));
            }}
          />
        )}

        {!editDate && !isFieldDisabled(FORM_FIELDS.DATES) && (
          <DateRow
            isOpen={openCardIndex === FORM_FIELDS.DATES}
            onToggle={() => handleCardToggle(FORM_FIELDS.DATES)}
            onConfirm={() => handleCardToggle(FORM_FIELDS.PROFESSIONAL)}
          />
        )}

        {isFieldDisabled(FORM_FIELDS.PROFESSIONAL) &&
          renderJobField({
            title: 'Professional:',
            value: !professionType ? 'Profession Type' : professionType,
            icon: userIcon,
          })}

        {!isFieldDisabled(FORM_FIELDS.PROFESSIONAL) && (
          <ProfessionalRow
            isOpen={openCardIndex === FORM_FIELDS.PROFESSIONAL}
            onToggle={() => handleCardToggle(FORM_FIELDS.PROFESSIONAL)}
            onConfirm={({ type }) => {
              setFormValues((prevState) => ({
                ...prevState,
                professionType: type,
                rate: 0,
              }));
            }}
            defaultSkill={skills}
            professionOptions={allProfessionOptions}
          />
        )}

        {isFieldDisabled(FORM_FIELDS.SKILLS) &&
          renderJobField({
            title: 'Shift Specialty:',
            value: job?.specialty?.split('_')?.[1] || 'General',
            icon: ToothIcon,
          })}

        {!isFieldDisabled(FORM_FIELDS.SKILLS) && (
          <SkillsRow
            isOpen={openCardIndex === FORM_FIELDS.SKILLS}
            onToggle={() => handleCardToggle(FORM_FIELDS.SKILLS)}
            onConfirm={({ selectedSkill }) => {
              setFormValues((prevState) => ({
                ...prevState,
                skills: selectedSkill,
              }));
            }}
            defaultSkill={skills}
            specialtiesAndProcedures={specialtiesAndProcedures}
          />
        )}

        {professionTypeCode === UserProfession.RDH && (
          <AssistedHygieneAvailableRow
            isOpen={openCardIndex === FORM_FIELDS.IS_ASSISTED_HYGIENE}
            onToggle={() => handleCardToggle(FORM_FIELDS.IS_ASSISTED_HYGIENE)}
            onConfirm={(value) => {
              setFormValues((prevState) => ({
                ...prevState,
                isAssistedHygiene: value,
              }));
            }}
            defaultValue={isAssistedHygiene}
          />
        )}

        {getShouldAllowOfficeToPostProcedure() && (
          <ProcedureCollapsibleSelection
            useRowStyle
            availableProcedures={availableProcedures}
            selectedProcedureIndices={selectedProcedureIndices}
            expanded={openCardIndex === FORM_FIELDS.PROCEDURES}
            onPressed={() => handleCardToggle(FORM_FIELDS.PROCEDURES)}
            onItemPressed={(index) => {
              const indices = (() => {
                const selected = selectedProcedureIndices.includes(index);
                const collection = selectedProcedureIndices;
                const maxSelection = 4;

                if (selected) {
                  return collection.filter((currentIndex) => currentIndex !== index);
                }

                if (!(maxSelection && collection.length >= maxSelection)) {
                  return [...collection, index];
                }

                return collection;
              })();

              setSelectedProcedureIndices(indices);
              setFormValues((prevState) => ({
                ...prevState,
                procedures: indices.map((index) => availableProcedures[index]),
              }));
            }}
          />
        )}

        <HoursRow
          isOpen={openCardIndex === FORM_FIELDS.HOURS}
          onToggle={() => handleCardToggle(FORM_FIELDS.HOURS)}
          defaultArrival={offerStartTime}
          defaultDeparture={offerEndTime}
          onConfirm={(arrival, departure) => {
            setFormValues((prevState) => ({
              ...prevState,
              offerStartTime: arrival,
              offerEndTime: departure,
            }));
            handleCardToggle(FORM_FIELDS.BREAK);
          }}
        />

        <BreakRow
          isOpen={openCardIndex === FORM_FIELDS.BREAK}
          onToggle={() => handleCardToggle(FORM_FIELDS.BREAK)}
          onConfirm={({ time, isPaid }) => {
            setFormValues((prevState) => ({ ...prevState, lunch: time, isLunchPaid: isPaid }));
          }}
          defaultBreak={lunch}
          defaultIsPaid={isLunchPaid}
        />

        <HourlyRateRow
          ref={rateRef}
          isOpen={openCardIndex === FORM_FIELDS.RATE}
          onToggle={() => handleCardToggle(FORM_FIELDS.RATE)}
          onConfirm={(offerRate) => {
            setFormValues((prevState) => ({ ...prevState, rate: offerRate }));
            handleCardToggle(FORM_FIELDS.INVITE);
          }}
          averagePrice={profRate}
          defaultValue={rate}
        />

        {!job && (
          <InviteRow
            isOpen={openCardIndex === FORM_FIELDS.INVITE}
            onToggle={() => handleCardToggle(FORM_FIELDS.INVITE)}
            onConfirm={(value) => {
              setFormValues((prevState) => ({
                ...prevState,
                professional: value,
              }));
            }}
            defaultProfessionalId={professional?.id}
          />
        )}

        <AutoConfirmRow
          onConfirm={({ eligibleProfessionals, favorites, highlyRated }) => {
            setFormValues((prevState) => ({
              ...prevState,
              enableAutoFill: eligibleProfessionals,
              enableAutoFillFavorites: favorites,
              enableAutoFillHighlyRated: highlyRated,
            }));
          }}
          value={{
            eligibleProfessionals: enableAutoFill || false,
            favorites: enableAutoFillFavorites || false,
            highlyRated: enableAutoFillHighlyRated || false,
          }}
          professionType={professionType}
          skills={skills}
        />
      </div>
    </>
  );
}
