import '../themes/navigation.scss';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router';

import { trackEvent } from '../api/analytics';
import { RemoteConfigUtils } from '../utils/RemoteConfigUtils';
import { searchChildOffice, selectOffice } from '../actions';
import SelectProfilePopup from '../commonComponents/SelectProfilePopup';
import browserHistory from '../history';
import CalendarIcon from '../images/icons/CalendarIcon';
import DashboardIcon from '../images/icons/DashboardIcon';
import DentistChairIcon from '../images/icons/DentistChairIcon';
import GiftIcon from '../images/icons/GiftIcon';
import UserGroup from '../images/icons/UserGroup';
import { Colors } from '../themes/colors';
import { getShouldEnableReferralProgramV2 } from '../growthbook';

const RenderLink = ({ route, isActive = false, Icon, title, isNew, listener }) => {
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  return (
    <div
      style={{
        padding: '9px 0',
        marginRight: !isNew ? 50 : 0,
        marginLeft: 18,
        paddingLeft: 32,
        ...(isHovering && {
          backgroundColor: '#E9F1F4',
          borderRadius: '6px',
        }),
      }}
      onClick={() => listener?.()}
    >
      <Link
        to={route}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        style={{
          color: !isActive ? Colors.neutral_600 : Colors.secondary_500,
          fontFamily: 'Nunito',
          fontSize: 20,
          fontWeight: 'bold',
          textDecoration: 'none',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 20,
          }}
        >
          <Icon color={isActive ? Colors.secondary_500 : Colors.neutral_600} />

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 4,
            }}
          >
            {title}
            {isNew && (
              <div
                style={{
                  fontFamily: 'Nunito',
                  fontSize: 12,
                  fontWeight: 700,
                  backgroundColor: Colors.secondary_500,
                  color: Colors.white,
                  width: 48,
                  height: 20,
                  borderRadius: 3,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                NEW
              </div>
            )}
          </div>
        </div>
      </Link>
    </div>
  );
};

export default function SideBar() {
  const dispatch = useDispatch();
  const { location } = browserHistory;
  const childOffices = useSelector((state) => state.user.childOffices);
  const allPPJobs = useSelector((state) => state.pp.allPPJobs);
  const stateToDisableReferralProgram = RemoteConfigUtils.getReferralUnavailableStates();
  const user = useSelector((state) => state.user.selectedChildOffice);
  const showConnectionsHubPopup = useSelector((state) => state.user.showConnectionsHubPopup);
  const [isHighlyRatedTabNew, setIsHighlyRatedTabNew] = useState(
    !localStorage.getItem(`isHighlyRatedTabNew-${user.id}`),
  );
  const isProfessionalHubNew =
    !localStorage.getItem(`isProfessionalHubNew-${user.id}`) ||
    isHighlyRatedTabNew ||
    showConnectionsHubPopup;
  const dashboardInfo = useSelector((state) => state.job.dashboardInfo);

  useEffect(() => {
    const handleStorageChange = () => {
      const newValue = localStorage.getItem(`isHighlyRatedTabNew-${user.id}`);
      setIsHighlyRatedTabNew(!newValue);
    };

    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [user.id]);

  const referralScreenDisabled = stateToDisableReferralProgram.includes(user.address?.[0]?.state);

  const renderProfileSelection = () => {
    if (childOffices && childOffices.length !== 0) {
      return (
        <div style={{ marginTop: 30, display: 'flex', flexDirection: 'column' }}>
          <SelectProfilePopup
            cardHasBennClicked={(officeId) => dispatch(selectOffice({ officeId }))}
            callSearchChildAction={(searchText) => dispatch(searchChildOffice({ searchText }))}
          />
        </div>
      );
    }

    return <div style={{ height: 40 }} />;
  };

  const renderPPLink = () => {
    if (allPPJobs && allPPJobs.length === 1) {
      return (
        <RenderLink
          route={`/dashboard/permanentJobs/${allPPJobs[0].id}`}
          isActive={location.pathname.includes('/dashboard/permanentJobs')}
          Icon={DentistChairIcon}
          title="Permanent Job"
        />
      );
    }
    if (allPPJobs && allPPJobs.length === 0) {
      return null;
    }
    return (
      <RenderLink
        route="/dashboard/permanentJobs"
        isActive={location.pathname.includes('/dashboard/permanentJobs')}
        Icon={DentistChairIcon}
        title="Permanent Jobs"
      />
    );
  };

  return (
    <div className="side_bar_contain">
      <div className="navigation_contain">
        {renderProfileSelection()}
        <div
          style={{
            marginTop: '10px',
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            gap: 18,
          }}
        >
          <RenderLink
            route="/"
            isActive={location.pathname === '/'}
            Icon={DashboardIcon}
            title="Dashboard"
            listener={() =>
              trackEvent('Office Dashboard Tab Clicked', {
                scheduledShiftsCount: dashboardInfo?.scheduled,
                openShiftsCount: dashboardInfo?.activeRequest,
                actionNeededShiftsCount: dashboardInfo?.actionRequired,
              })
            }
          />
          {renderPPLink()}
          <RenderLink
            route="/dashboard/calendar"
            isActive={location.pathname === '/dashboard/calendar'}
            Icon={CalendarIcon}
            title="Schedule"
            listener={() =>
              trackEvent('Office Schedule Tab Clicked', {
                scheduledShiftsCount: dashboardInfo?.scheduled,
                openShiftsCount: dashboardInfo?.activeRequest,
                actionNeededShiftsCount: dashboardInfo?.actionRequired,
              })
            }
          />
          <RenderLink
            route="/professionals-hub/favorites-list"
            isActive={location.pathname.includes('/professionals-hub')}
            Icon={UserGroup}
            title="Professionals Hub"
            isNew={isProfessionalHubNew}
            listener={() =>
              trackEvent('Office Professional Hub Tab Clicked', {
                scheduledShiftsCount: dashboardInfo?.scheduled,
                openShiftsCount: dashboardInfo?.activeRequest,
                actionNeededShiftsCount: dashboardInfo?.actionRequired,
              })
            }
          />
          {!referralScreenDisabled && (
            <RenderLink
            route="/dashboard/referral"
            isActive={location.pathname === '/dashboard/referral'}
            Icon={GiftIcon}
            title="Referral Program"
            isNew={
              getShouldEnableReferralProgramV2() &&
              !localStorage.getItem(`isReferralProgramNew-${user.id}`)
            }
            listener={() => {
              if (getShouldEnableReferralProgramV2()) {
                localStorage.setItem(`isReferralProgramNew-${user.id}`, 'false');
              }
            }}
          />
          )}
        </div>
      </div>
    </div>
  );
}
