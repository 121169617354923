import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchJob } from '../actions';
import { useParams } from 'react-router';
import history from '~/history';

export default function VirtualJobRedirection() {
  const params = useParams();
  const { jobId } = params;
  const dispatch = useDispatch();

  const job = useSelector((state) => state.job.job);
  useEffect(() => {
    dispatch(fetchJob({ jobId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (job) {
      if (job.status === 'open' || job.status === 'counter') {
        history.push(`/dashboard/job/${jobId}`, {
          jobId,
        });
      } else if (job.status === 'pending') {
        history.push(`/dashboard/confirm-hygienist/${jobId}`);
      } else {
        history.push(`/dashboard/job/${jobId}/details`, {
          job_id: jobId,
          job_status: job.status,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job]);

  return <></>;
}
