import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { hideConfirmCandidateAnotherJobPopup } from '../../../actions';
import { trackEvent } from '../../../api/analytics';
import AnimatedCheckCircle from '../../shared/AnimatedCheckCircle';
import ConfirmPopup from '../../shared/ConfirmPopup';
import { useStateGuard } from '~/hooks';
import { shouldShowConfirmCandidateInBulk } from '~/growthbook';

export const ConfirmCandidateAnotherJobPopup = ({ job, candidate }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pendingJobs = useSelector((state) => state.job.actionRequiredJobs);

  const isWhitelistedState = useStateGuard(['PA', 'MI', 'MA']);

  // Sort pending jobs by closest date to today
  const sortedPendingJobs = pendingJobs
    .filter(
      (j) => j.id !== job?.id && j.multipleCounters.some((c) => c.candidate.id === candidate?.id),
    )
    .sort((a, b) =>
      moment(a.local_date, 'ddd, MMM D, YYYY').diff(moment(b.local_date, 'ddd, MMM D, YYYY')),
    );

  const nextJob = sortedPendingJobs[0];

  return (
    <ConfirmPopup
      Icon={() => (
        <div style={{ marginBottom: 20 }}>
          <AnimatedCheckCircle />
        </div>
      )}
      title={`Candidate Confirmed for ${job?.local_date}!`}
      description={
        shouldShowConfirmCandidateInBulk() ? (
          <span>
            {candidate?.full_name} has also applied to another shift at your office. Would you like
            to review their application?
          </span>
        ) : (
          <span>
            {candidate?.full_name} has also applied to another shift at your office on{' '}
            <b>{nextJob?.local_date}</b>. Would you like to review their application?
          </span>
        )
      }
      rightButtonAction={() => {
        trackEvent('Candidate Confirmed Popup - Review Button Clicked');

        if (isWhitelistedState && shouldShowConfirmCandidateInBulk()) {
          navigate(`/dashboard/candidates/${candidate?.id}/jobs`);
          return;
        }

        dispatch(hideConfirmCandidateAnotherJobPopup());
        navigate(`/dashboard/job/${nextJob.id}/confirm/${candidate?.id}`);
      }}
      rightButtonText="Review"
      leftButtonText="No Thanks"
      leftButtonAction={() => {
        trackEvent('Candidate Confirmed Popup - No Thanks Button Clicked');
        dispatch(hideConfirmCandidateAnotherJobPopup());
        navigate(`/dashboard/jobs/${pendingJobs?.length > 0 ? 'pending' : 'scheduled'}`);
      }}
    />
  );
};
