import { ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router';

type TProps = {
  title?: string;
  children: ReactNode;
};

export const PageAnalytics = ({ title, children }: TProps) => {
  const location = useLocation();

  useEffect(() => {
    if (title) {
      // @ts-expect-error -- Send analytics page view
      window.analytics.page(title);

      if (process.env.REACT_APP_TEMP_MEE_ENV === 'development') {
        console.debug(`Viewed ${title}`);
      }
    }
  }, [title, location.pathname]);

  return children;
};
