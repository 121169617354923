import { routes } from '~/routes';
import { BackButton, Box, Center, Text } from '~/commonComponents';

export const ReferralDisclaimerScreen = () => {
  return (
    <Box className="component_container" flexDirection="initial">
      <Box className="floating_container" padding={40}>
        {/* @ts-expect-error - missing props */}
        <BackButton url={routes.referral} />

        <Center width={'85%'}>
          <Text bold textAlign="center" fontSize={32}>
            Referral Program Disclaimer
          </Text>

          <Text color="neutral_500" marginTop={32}>
            When you share your referral link with others, you hereby acknowledge and accept the
            terms and conditions of GoTu&apos;s referral program.
            <br />A Valid Referral is defined as an office or professional who has successfully
            joined and completed a shift on GoTu&apos;s Platform (&quot;Valid Referral&quot;). For
            each Valid Referral, you and your Valid Referral will be rewarded as follows:
          </Text>

          <ul>
            <li>
              <Text color="neutral_500">
                <b>For Professionals:</b> Professionals who refer and Valid Referrals who are
                Professionals, will be eligible to spin the wheel to receive a cash referral reward
                amount between $25 and $200. You and your Valid Referral will receive a text message
                and a push notification in the GoTu App to access the Referral Reward. You each will
                be instructed to log into the GoTu App and spin the wheel to identify your Referral
                Reward amount. You must spin the wheel to determine your cash reward amount (between
                $25 to $200). All Referral Rewards are subject to tax reporting requirements.
              </Text>
            </li>
            <li>
              <Text color="neutral_500">
                <b>For Offices:</b> Offices which refer and Valid Referrals which are Offices will
                receive a $50 e-gift card. All Referral Rewards are subject to tax reporting
                requirements.
              </Text>
            </li>
          </ul>

          <Text color="neutral_500">
            Each Valid Referral, subject to the restrictions set forth below, who joins GoTu&apos;s
            Platform with a unique link and successfully completes their first shift will be
            eligible for a Referral Reward. To be a Valid Referral your referral must not be an
            existing user of the app, must be a first-time user of the app as a result of your
            referral, and must provide a valid government-issued photo I.D., or equivalent form of
            identification as may be requested by GoTu. Please note, child offices and offices
            within the same DSO network are not eligible for referral.
            <br />
            Referrals are subject to verification; GoTu may delay a Referral Reward for the purposes
            of verification. GoTu may also decline to verify and process any referral for any
            reason. Referral Rewards are not transferable and may not be auctioned, traded,
            bartered, or sold.
            <br />
            For further questions, feel free to contact our team via call or text at{' '}
            <u>786-460-2170.</u>
          </Text>

          <br />

          <Text color="neutral_300">
            Professionals & Offices in the following states are not eligible to participate in
            GoTu&apos;s referral program or receive referral payments: Massachusetts, Connecticut,
            California.
          </Text>
        </Center>
      </Box>
    </Box>
  );
};
