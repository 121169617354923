import { useMemo } from 'react';
import { Box, Column, Divider, Pressable, Row, Text } from '~/commonComponents';
import Checkbox from '~/commonComponents/Checkbox';
import Tooltip from '~/components/Tooltip';
import { IJob } from '~/models';
import { Colors } from '~/themes/colors';
import { ShiftListSelectionMode } from './enums';
import { ShiftItem } from './ShiftItem';

import './style.scss';

interface IProps {
  checkedJobs: Record<string, boolean>;
  isMultipleSelection: boolean;
  jobs: IJob[];
  handleToggleOption: (jobId?: string) => void;
}

export const ShiftList = ({
  checkedJobs,
  isMultipleSelection,
  jobs = [],
  handleToggleOption,
}: IProps) => {
  const columns = useMemo(
    () => [
      {
        title: isMultipleSelection ? 'Select All' : 'Select',
        style: {
          maxWidth: '10%',
        },
        render: isMultipleSelection
          ? () => {
              return (
                <Pressable onPress={() => handleToggleOption()}>
                  <Row gap={4} alignItems="center" justifyContent="center">
                    <Checkbox checked={Object.keys(checkedJobs).length === jobs.length} />
                    <Text bold color="primary_500" fontSize={18} textAlign="center">
                      Select All
                    </Text>
                  </Row>
                </Pressable>
              );
            }
          : undefined,
      },
      {
        title: 'Shift Date',
      },
      {
        title: 'Profession',
      },
      {
        title: 'Hours',
      },
      {
        title: 'Hourly Rate',
      },
      {
        title: 'Total Pay',
        render: () => {
          return (
            <Row justifyContent="center">
              <Text bold color="primary_500" fontSize={18} textAlign="center">
                Total Pay
              </Text>
              <Tooltip
                // @ts-expect-error - currently Tooltip still written as Javascript Component.
                content={
                  isMultipleSelection
                    ? 'Total Pay is the total payment owed for this shift, including professional pay, GoTu service fee, and convenience fee if applicable.'
                    : 'Total Pay is the total payment owed for this shift, including professional pay, GoTu service fee, employee fee, and any other applicable fees and/or overtime charges.'
                }
              />
            </Row>
          );
        },
      },
      {
        title: 'View Application',
        style: {
          maxWidth: '10%',
        },
      },
    ],
    [checkedJobs, handleToggleOption, isMultipleSelection, jobs.length],
  );

  return (
    <Column>
      <Row alignItems="center">
        {columns.map(({ title, render, style }, i) => (
          <Box key={i} style={{ flex: 1, ...style }}>
            {render ? (
              render()
            ) : (
              <Text bold color="primary_500" fontSize={18} textAlign="center">
                {title}
              </Text>
            )}
          </Box>
        ))}
      </Row>
      <Divider color={Colors.neutral_50} height={1} />
      <Column gap={8}>
        {jobs.map((job) => (
          <ShiftItem
            key={job.id}
            isChecked={checkedJobs[job.id]}
            job={job}
            mode={
              isMultipleSelection ? ShiftListSelectionMode.Multiple : ShiftListSelectionMode.Single
            }
            handleToggleOption={handleToggleOption}
          />
        ))}
      </Column>
    </Column>
  );
};
