import Lottie from 'lottie-web';
import { useEffect, useRef } from 'react';
import circularLoadingPath from './json/circular-loading.json';

export const CircularLoading = () => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }

    const anim = Lottie.loadAnimation({
      container: containerRef.current,
      loop: false,
      autoplay: true,
      animationData: circularLoadingPath,
    });

    anim.addEventListener('complete', () => {
      anim.goToAndPlay(0);
    });

    return () => {
      anim.destroy();
    };
  }, []);

  return (
    <div
      ref={containerRef}
      style={{
        width: '152px',
        height: '152px',
      }}
    />
  );
};
