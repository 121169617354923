import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { Box, CardSpinner, Center, Pressable, Row, Text } from '~/commonComponents';
import { useLoading } from '~/hooks';
import { routes } from '~/routes';
import { useAppSelector } from '~/hooks/useAppSelector';
import {
  CREATE_REFERRAL_LINK,
  createReferralLink,
  GET_REFERRAL_LIST_ACTION,
  GET_REFERRAL_PAYOUTS_ACTION,
  GET_REFERRAL_SUMMARY_ACTION,
  getReferralPayouts,
} from '~/actions';
import { Colors } from '~/themes/colors';
import { InstructionItem } from './components/InstructionItem';
import { ReferralLink } from './components/ReferralLink';
import { ReferralSummary } from './components/ReferralSummary';
import { ReferralList } from './components/ReferralList';
import { useReferralList } from './hooks/useReferralList';

// @ts-expect-error - unable to resolve path
import ReferralGiftCard from '~/images/referral/referral-gift-card.svg';
// @ts-expect-error - unable to resolve path
import ShareIcon from '~/images/referral/referral-share-icon.svg';
// @ts-expect-error - unable to resolve path
import PeopleIcon from '~/images/referral/referral-people-icon.svg';
// @ts-expect-error - unable to resolve path
import GiftIcon from '~/images/referral/referral-gift-icon.svg';

const loadingActions = [
  CREATE_REFERRAL_LINK,
  GET_REFERRAL_LIST_ACTION,
  GET_REFERRAL_SUMMARY_ACTION,
  GET_REFERRAL_PAYOUTS_ACTION,
];

export const ReferralScreenV2 = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { referralLink } = useAppSelector((state) => state.user);

  const { isLoading } = useLoading(loadingActions);

  const { totalUserReferred, totalEarned, referralList, fetchReferralList } = useReferralList();

  useEffect(() => {
    dispatch(createReferralLink());
    dispatch(getReferralPayouts());
    fetchReferralList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  if (isLoading) {
    return <CardSpinner />;
  }

  return (
    <>
      <Box className="component_container" flexDirection="initial">
        <Box className="floating_container" padding={40}>
          <Center>
            <Text bold textAlign="center" fontSize={32}>
              Referral Program
            </Text>

            <img
              src={ReferralGiftCard}
              style={{ width: 203, height: 169, paddingTop: 40 }}
              alt="icon"
            />

            <Text color="primary_500" bold fontSize={22} paddingTop={32}>
              Invite Friends,{' '}
              <Text color="success_400" bold fontSize={22}>
                Get an E-Gift Card!
              </Text>
            </Text>

            <Row gap={32} paddingTop={26} width={'90%'}>
              <InstructionItem
                icon={ShareIcon}
                title="1. Share Your Code"
                description="Send your link to as many friends or offices as you like!"
              />

              <InstructionItem
                icon={PeopleIcon}
                title="2. Your Friend Completes a Shift"
                description="Once your friend completes their first shift successfully, we’ll notify you both."
              />

              <InstructionItem
                icon={GiftIcon}
                title="3. You Both Get Rewards!"
                description="That’s it! You’ll both receive a referral reward!"
              />
            </Row>

            <Box height={1} width="70%" backgroundColor={Colors.neutral_100} marginTop={40} />

            {referralList?.length > 0 ? (
              <ReferralSummary
                totalReferrals={totalUserReferred || 0}
                totalEarned={totalEarned || 0}
                style={{ marginTop: 32 }}
              />
            ) : null}

            <ReferralLink link={referralLink || ''} style={{ marginTop: 32 }} />

            <ReferralList data={referralList} style={{ marginTop: 46 }} />

            <Pressable
              onPress={() => navigate(routes.referralDisclaimer)}
              style={{ paddingBlock: 40 }}
            >
              <Text bold color="secondary_500">
                Disclaimer/ Terms and Conditions
              </Text>
            </Pressable>
          </Center>
        </Box>
      </Box>
    </>
  );
};
