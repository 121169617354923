import { useEffect, useState } from 'react';
import { hotjar } from 'react-hotjar';
import { useUserSelector } from '../useUserSelector';

export const useCookieYes = () => {
  const user = useUserSelector();
  const [consent, setConsent] = useState<string | null>(null);

  const getConsentValue = () => {
    const cookieMatch = document.cookie
      .split('; ')
      .find((row) => row.startsWith('cookieyes-consent='));

    if (!cookieMatch) return null;

    const cookieValue = decodeURIComponent(cookieMatch.split('=')[1]);
    const consentObj = Object.fromEntries(cookieValue.split(',').map((item) => item.split(':')));

    return consentObj['consent'] || null;
  };

  useEffect(() => {
    if (consent === 'yes') return; // Stop checking if consent is already 'yes'

    const checkConsent = () => {
      const newConsent = getConsentValue();
      if (newConsent !== consent) {
        setConsent(newConsent);
      }
    };

    checkConsent(); // Initial check

    const interval = setInterval(checkConsent, 3000); // Poll every 3s

    return () => clearInterval(interval);
  }, [consent]);

  useEffect(() => {
    if (consent === 'yes') {
      console.log('Initializing Hotjar...');
      hotjar.initialize({
        id: process.env.REACT_APP_HOTJAR_ID as unknown as number,
        sv: process.env.REACT_APP_HOTJAR_SV as unknown as number,
        debug: process.env.REACT_APP_TEMP_MEE_ENV === 'development',
      });
    }
  }, [consent]);

  useEffect(() => {
    if (hotjar.initialized() && user) {
      console.log('Identifying user in Hotjar...');
      hotjar.identify(user?.id, {
        id: user?.id,
        userType: user?.user_type,
        ...(user?.address && user?.address[0] && { state: user.address[0].state }),
      });
    }
  }, [user]);
};
