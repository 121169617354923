import { acceptPSATerms, acceptTerms } from '../../actions';
import { Links } from '../../constants';
import OneLastStepSVG from '../../images/signup/success/oneLastStep.svg';
import { Link } from './Link';

export const POPUP_VALUES = {
  oneLastStep: {
    title: 'One Last Step',
    description:
      'We will be providing professionals that are employed through our PEO, GoTu’s Professional Employment Organization (PEO). Please read through and accept the Personnel Service Agreement to begin posting shifts!',
    buttonText: 'Done',
    image: OneLastStepSVG,
    onButtonClick: [acceptPSATerms],
    switchText: (
      <>
        I agree to GoTu’s{' '}
        <Link text="Personnel Service Agreement" href={Links.termsOfUseEmployee} />
      </>
    ),
  },
  termsAndConditions: {
    title: 'GoTu Terms and Conditions',
    description: <>Please read through and accept our updated Terms and Conditions.</>,
    buttonText: 'Confirm',
    onButtonClick: [acceptTerms],
    image: null,
    switchText: (
      <>
        I agree to <Link text="Terms and Conditions" href={Links.termsOfUse} />
      </>
    ),
  },
  termsAndConditionsEmployee: {
    title: 'GoTu Terms and Conditions',
    description: <>Please read through and accept our updated Terms and Conditions.</>,
    buttonText: 'Confirm',
    onButtonClick: [acceptTerms, acceptPSATerms],
    image: null,
    switchText: (
      <>
        I agree to <Link text="Terms and Conditions" href={Links.termsOfUse} /> and{' '}
        <Link text="Personnel Service Agreement" href={Links.termsOfUseEmployee} />
      </>
    ),
  },
};

export const getPopupValues = (user, gotuTermsAccepted) => {
  if (user?.workerClassification?.classificationType === 'employee') {
    if (!user?.office_info?.psaTerms?.accepted && !gotuTermsAccepted) {
      return POPUP_VALUES.termsAndConditionsEmployee;
    }

    if (
      !user?.office_info?.psaTerms?.accepted &&
      localStorage.getItem(`showDualModelTermsPopUp-${user?.id}`) === 'false'
    ) {
      return POPUP_VALUES.oneLastStep;
    }

    if (!gotuTermsAccepted) {
      return POPUP_VALUES.termsAndConditions;
    }

    return null;
  }

  if (user?.workerClassification?.classificationType === 'contractor') {
    if (!gotuTermsAccepted) {
      return POPUP_VALUES.termsAndConditions;
    }
  }

  if (!gotuTermsAccepted) {
    return POPUP_VALUES.termsAndConditions;
  }

  return null;
};
