import { Box, Row, Text } from '~/commonComponents';
import { IReferral, ReferralListStatuses, UserTypeEnum } from '~/models';
import { EmptyReferral } from './EmptyReferral';

// @ts-expect-error - unable to resolve path
import HouseIcon from '~/images/house-icon.svg';
// @ts-expect-error - unable to resolve path
import UserIcon from '~/images/user-icon.svg';
// @ts-expect-error - unable to resolve path
import CalendarCheckIcon from '~/images/calendar-check-icon.svg';
// @ts-expect-error - unable to resolve path
import BriefcaseIcon from '~/images/briefcase.svg';
// @ts-expect-error - unable to resolve path
import DollarIcon from '~/images/dollar-icon.svg';

interface IReferralListProps {
  data: IReferral[];
  style?: React.CSSProperties;
}

const referralStatusIconMap = {
  [ReferralListStatuses.AccountCreated]: UserIcon,
  [ReferralListStatuses.FirstShiftScheduled]: CalendarCheckIcon,
  [ReferralListStatuses.FirstShiftCompleted]: BriefcaseIcon,
  [ReferralListStatuses.PaymentSent]: DollarIcon,
};

export const ReferralList = ({ data = [], style }: IReferralListProps) => {
  const renderStatusIcon = (status: ReferralListStatuses) => {
    const Icon = referralStatusIconMap[status];

    return Icon ? (
      <img src={Icon} alt="icon" style={{ width: 19, height: 19, marginLeft: 8 }} />
    ) : null;
  };

  if (data?.length === 0) {
    return <EmptyReferral />;
  }

  return (
    <Box width={'549px'} style={style}>
      {data.map((item, idx) => (
        <Row
          key={item.id}
          paddingTop={24}
          paddingBottom={22}
          alignItems="center"
          justifyContent="space-between"
          style={{
            borderBottom: idx === data.length - 1 ? 'none' : '1px solid #EDEDED',
          }}
        >
          <Row alignItems="center">
            {item.referredUserType === UserTypeEnum.DNT && (
              <img src={HouseIcon} alt="icon" style={{ width: 24, height: 24, marginRight: 5 }} />
            )}
            <Text bold>{item.referredName}</Text>
          </Row>

          <Row alignItems="center">
            <Text>{item.referralStatus}</Text>
            {renderStatusIcon(item.referralStatus)}
          </Row>
        </Row>
      ))}
    </Box>
  );
};
