import { useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { Box, Center, Column, Icon, Text, ZStack } from '../../commonComponents';
import BackButton from '../../commonComponents/BackButton';
import Button from '../../commonComponents/Button';
import WarningBanner from '../../commonComponents/WarningBanner';
import { Colors } from '../../themes/colors';
import ConfirmPopup from '../shared/ConfirmPopup';
import { CollapsibleCardsGroup } from './components/CollapsibleCardsGroup/CollapsibleCardsGroup';
import { useAccountInformation } from './hooks/useAccountInformation/useAccountInformation';

export const AccountInformation = () => {
  const navigate = useNavigate();
  const [showExitWithoutSavingPopup, setShowExitWithoutSavingPopup] = useState(false);
  const { methods, onSubmit, isLoading, isButtonDisabled, isDirty, isValid, hasWarning } =
    useAccountInformation();

  const handleBackButtonClick = () => {
    if (!isDirty) {
      navigate(-1);
    } else {
      setShowExitWithoutSavingPopup(true);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Box className="component_container" justifyContent="flex-start">
        <Column className="floating_container" paddingBottom={50} width="auto">
          <Box position="relative" marginBottom={32} marginTop={60}>
            <Center>
              <ZStack left={20}>
                <BackButton onClick={handleBackButtonClick} />
              </ZStack>

              <Text fontSize={32} bold textAlign="center">
                Account Information
              </Text>
            </Center>
          </Box>
          <Box
            backgroundColor={Colors.neutral_50}
            flex={1}
            marginHorizontal={60}
            paddingBottom={80}
            paddingHorizontal={100}
            paddingTop={32}
            width="auto"
          >
            {(!isValid || hasWarning) && (
              <Box marginBottom={-8}>
                <WarningBanner
                  iconColor={Colors.warning_500}
                  primaryColor={Colors.warning_700}
                  description="Missing information: Complete missing fields for higher chances of filling shifts."
                />
              </Box>
            )}

            <FormProvider {...methods}>
              <CollapsibleCardsGroup />

              <Center marginTop={60}>
                <Button
                  text="Save"
                  disabled={isButtonDisabled}
                  size="medium"
                  onClick={onSubmit}
                  isLoading={isLoading}
                />
              </Center>
            </FormProvider>
          </Box>
        </Column>
      </Box>
      {showExitWithoutSavingPopup && (
        <ConfirmPopup
          Icon={() => <Icon name="warning-outline" size={80} />}
          leftButtonAction={() => setShowExitWithoutSavingPopup(false)}
          leftButtonText="Go Back"
          rightButtonAction={() => navigate(-1)}
          rightButtonText="Leave"
          title="Leave Screen"
          titleStyle={{ marginBottom: 20, marginTop: 20 }}
          descriptionStyle={{ marginBottom: 40 }}
          description="Are you sure you would like to leave this page without saving the changes made to your account information?"
        />
      )}
    </>
  );
};
