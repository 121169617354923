import { useDispatch } from 'react-redux';
import { useAppSelector } from '~/hooks/useAppSelector';
import { getReferralList, getReferralSummary } from '~/actions';

export const useReferralList = () => {
  const dispatch = useDispatch();
  const { referralList, totalUserReferred, totalEarned } = useAppSelector((state) => state.user);

  const fetchReferralList = () => {
    dispatch(getReferralList());
    dispatch(getReferralSummary());
  };

  return {
    totalUserReferred,
    totalEarned,
    referralList,
    fetchReferralList,
  };
};
