const REQUEST = 'REQUEST';
const SUCCESS = 'SUCCESS';
const FAILURE = 'FAILURE';

export function createRequestTypes(base) {
  return [REQUEST, SUCCESS, FAILURE].reduce((acc, type) => {
    acc[type] = `${base}_${type}`;
    return acc;
  }, {});
}

function action(type, payload = {}) {
  return { type, ...payload };
}

export const NO_CARD_ERROR = 453;
export const DUPLICATE_JOB_ERROR = 454;

export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const showMessage = (payload) => action(SHOW_MESSAGE, { payload });

export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const hideMessage = () => action(HIDE_MESSAGE);

export const SHOW_REGISTRATION_SUCCESS_POPUP = 'SHOW_REGISTRATION_SUCCESS_POPUP';
export const showRegistrationPopup = () => action(SHOW_REGISTRATION_SUCCESS_POPUP);

export const HIDE_REGISTRATION_SUCCESS_POPUP = 'HIDE_REGISTRATION_SUCCESS_POPUP';
export const hideRegistrationPopup = () => action(HIDE_REGISTRATION_SUCCESS_POPUP);

export const SHOW_CREATE_JOB_MESSAGE = 'SHOW_CREATE_JOB_MESSAGE';
export const showCreateJobMessage = () => action(SHOW_CREATE_JOB_MESSAGE);

export const HIDE_CREATE_JOB_MESSAGE = 'HIDE_CREATE_JOB_MESSAGE';
export const hideCreateJobMessage = () => action(HIDE_CREATE_JOB_MESSAGE);

export const SHOW_JOB_CREATE_PAYMENT_ERROR = 'SHOW_JOB_CREATE_PAYMENT_ERROR';
export const showJobCreatePaymentError = () => action(SHOW_JOB_CREATE_PAYMENT_ERROR);

export const HIDE_JOB_CREATE_PAYMENT_ERROR = 'HIDE_JOB_CREATE_PAYMENT_ERROR';
export const hideJobCreatePaymentError = () => action(HIDE_JOB_CREATE_PAYMENT_ERROR);

export const FETCH_AVERAGE_RATING = 'FETCH_AVERAGE_RATING';
export const fetchAverageRating = () => action(FETCH_AVERAGE_RATING);

export const INITIAL_LOADING = 'INITIAL_LOADING';
export const initialLoading = () => action(INITIAL_LOADING);

export const SIGN_IN_ACTION = 'SIGN_IN_ACTION';
export const signIn = (cred) => action(SIGN_IN_ACTION, cred);

export const GET_CHILD_OFFICES_ACTION = 'GET_CHILD_OFFICES_ACTION';
export const getChildOffices = () => action(GET_CHILD_OFFICES_ACTION);

export const SIGN_UP_ACTION = 'SIGN_UP_ACTION';
export const signUp = (cred) => action(SIGN_UP_ACTION, cred);

export const FORGOT_PASSWORD_SEND_CODE_ACTION = 'FORGOT_PASSWORD_SEND_CODE_ACTION';
export const forgotPasswordSendCode = (cred) => action(FORGOT_PASSWORD_SEND_CODE_ACTION, cred);

export const FORGOT_PASSWORD_RESET_ACTION = 'FORGOT_PASSWORD_RESET_ACTION';
export const forgotPasswordReset = (cred) => action(FORGOT_PASSWORD_RESET_ACTION, cred);

export const HIDE_FORGOT_PASSWORD_ERROR_MODAL = 'HIDE_FORGOT_PASSWORD_ERROR_MODAL';
export const hideForgotPasswordErrorModal = () => action(HIDE_FORGOT_PASSWORD_ERROR_MODAL);

export const CLEAR_FORGOT_PASSWORD_STATE = 'CLEAR_FORGOT_PASSWORD_STATE';
export const clearForgotPasswordState = () => action(CLEAR_FORGOT_PASSWORD_STATE);

export const EDIT_GENERAL_INFO_ACTION = 'EDIT_GENERAL_INFO_ACTION';
export const editUserGeneral = (data) => action(EDIT_GENERAL_INFO_ACTION, data);

export const EDIT_USER_OFFICE_INFO = 'EDIT_USER_OFFICE_INFO';
export const editUserOfficeInfo = (data) => action(EDIT_USER_OFFICE_INFO, data);

export const EDIT_USER = 'EDIT_USER';
export const editUser = (data) => action(EDIT_USER, data);

export const BLOCK_PROFESSIONAL = 'BLOCK_PROFESSIONAL';
export const blockProfessional = (data) => action(BLOCK_PROFESSIONAL, data);

export const IS_OVERLAPPING_JOB = 'IS_OVERLAPPING_JOB';
export const isOverlapingJob = (data) => action(IS_OVERLAPPING_JOB, data);

export const EDIT_ACCOUNT_INFO_ACTION = 'EDIT_ACCOUNT_INFO_ACTION';
export const editUserAccount = (data) => action(EDIT_ACCOUNT_INFO_ACTION, data);

export const LOGOUT_ACTION = 'LOGOUT_ACTION';
export const logOut = (token) => action(LOGOUT_ACTION, token);

export const SEND_NPS_REVIEW = 'SEND_NPS_REVIEW';
export const sendNpsReview = (data) => action(SEND_NPS_REVIEW, data);

export const UPDATE_RATING_COUNT = 'UPDATE_RATING_COUNT';
export const updateRatingCount = (payload) => action(UPDATE_RATING_COUNT, { payload });

export const SEND_FEEDBACK_TEMPMEE = 'SEND_FEEDBACK_TEMPMEE';
export const sendTempmeeFeedback = (data) => action(SEND_FEEDBACK_TEMPMEE, data);

export const CLOSE_MODAL_ACTION = 'CLOSE_MODAL_ACTION';
export const closeModal = () => action(CLOSE_MODAL_ACTION);

export const PHONE_VERIFICATION_ACTION = 'PHONE_VERIFICATION_ACTION';
export const phoneVerification = (code) => action(PHONE_VERIFICATION_ACTION, code);

export const GET_ALL_PROFESSIONS_ACTION = 'GET_ALL_PROFESSIONS_ACTION';
export const getAllProfessionals = () => action(GET_ALL_PROFESSIONS_ACTION);

export const GET_ALL_SPECIALIZATIONS_ACTION = 'GET_ALL_SPECIALIZATIONS_ACTION';
export const getAllSpecializations = () => action(GET_ALL_SPECIALIZATIONS_ACTION);

export const GET_USER_EDUCATION_ACTION = 'GET_USER_EDUCATION_ACTION';
export const getUserEducation = (data) => action(GET_USER_EDUCATION_ACTION, data);

export const GET_OFFICE_EDUCATION_ACTION = 'GET_OFFICE_EDUCATION_ACTION';
export const getOfficeEducation = (data) => action(GET_OFFICE_EDUCATION_ACTION, data);

export const UPDATE_USER_EDUCATION_ACTION = 'UPDATE_USER_EDUCATION_ACTION';
export const updateUserEducation = (data) => action(UPDATE_USER_EDUCATION_ACTION, data);

export const UPLOAD_AVATAR_ACTION = 'UPLOAD_AVATAR_ACTION';
export const uploadAvatar = (data) => action(UPLOAD_AVATAR_ACTION, data);

export const GET_ALL_SOFTWARES_ACTION = 'GET_ALL_SOFTWARES_ACTION';
export const getAllSoftwares = () => action(GET_ALL_SOFTWARES_ACTION);

export const ACCEPT_TERMS_ACTION = 'ACCEPT_TERMS_ACTION';
export const acceptTerms = () => action(ACCEPT_TERMS_ACTION);

export const ACCEPT_PSA_TERMS_ACTION = 'ACCEPT_PSA_TERMS_ACTION';
export const acceptPSATerms = () => action(ACCEPT_PSA_TERMS_ACTION);

export const GET_ALL_PAYMENTS_ACTION = 'GET_ALL_PAYMENTS_ACTION';
export const getAllPaymnets = () => action(GET_ALL_PAYMENTS_ACTION);

export const ADD_PAYMENT_ACTION = 'ADD_PAYMENT_ACTION';
export const addPayment = (data) => action(ADD_PAYMENT_ACTION, data);

export const GET_PLAID_TOKEN = 'GET_PLAID_TOKEN';
export const getPlaidToken = (data) => action(GET_PLAID_TOKEN, data);

export const SUBMIT_PLAID_TOKEN = 'SUBMIT_PLAID_TOKEN';
export const submitPlaidToken = (data) => action(SUBMIT_PLAID_TOKEN, data);

export const DELETE_PAYMENT_ACTION = 'DELETE_PAYMENT_ACTION';
export const deletePayment = (paymentId) => action(DELETE_PAYMENT_ACTION, paymentId);

export const SELECT_PAYMENT_ACTION = 'SELECT_PAYMENT_ACTION';
export const selectPayment = (paymentId) => action(SELECT_PAYMENT_ACTION, paymentId);

export const DELETE_DEFAULT_PAYMENT_ACTION = 'DELETE_DEFAULT_PAYMENT_ACTION';
export const deleteDefaultPayment = ({ oldPaymentId, newPaymentId }) =>
  action(DELETE_DEFAULT_PAYMENT_ACTION, { oldPaymentId, newPaymentId });

export const VERIFY_BANK_ACTION = 'VERIFY_BANK_ACTION';
export const verifyBank = (paymentId) => action(VERIFY_BANK_ACTION, paymentId);

// dso

export const SELECT_OFFICE = 'SELECT_OFFICE';
export const selectOffice = (officeId) => action(SELECT_OFFICE, officeId);

export const SEARCH_OFFICE_ACTION = 'SEARCH_OFFICE_ACTION';
export const searchChildOffice = (searchText) => action(SEARCH_OFFICE_ACTION, searchText);

// feedback

export const FEEDBACK_REQUIRED = 'FEEDBACK_REQUIRED';
export const feedbackRequire = () => action(FEEDBACK_REQUIRED);

export const FEEDBACK_SAVE_ACTION = 'FEEDBACK_SAVE_ACTION';
export const feedbackSave = (data) => action(FEEDBACK_SAVE_ACTION, data);

export const SEND_PROCEDURE_ENDORSEMENT = 'SEND_PROCEDURE_ENDORSEMENT';
export const sendProcedureEndorsement = ({ endorsement, userId }) =>
  action(SEND_PROCEDURE_ENDORSEMENT, { endorsement, userId });

export const FETCH_USER_PROCEDURE_ENDORSEMENT = 'FETCH_USER_PROCEDURE_ENDORSEMENT';
export const fetchUserProcedureEndorsement = ({ candidateId, profession }) =>
  action(FETCH_USER_PROCEDURE_ENDORSEMENT, { candidateId, profession });

// pp
export const FETCH_ALL_PP_JOBS = 'FETCH_ALL_PP_JOBS';
export const fetchAllPPJobs = () => action(FETCH_ALL_PP_JOBS);

export const FETCH_APPLICATIONS_PP = 'FETCH_APPLICATIONS_PP';
export const fetchPPapplications = (jobId) => action(FETCH_APPLICATIONS_PP, jobId);

export const FETCH_APPLICATION_BY_ID_PP = 'FETCH_APPLICATION_BY_ID_PP';
export const fetchPPapplicationById = (jobId, applicationId) =>
  action(FETCH_APPLICATION_BY_ID_PP, jobId, applicationId);

export const POST_ACTION_PP = 'POST_ACTION_PP';
export const postPPAction = (jobId, applicationId, actionType, notes) =>
  action(POST_ACTION_PP, jobId, applicationId, actionType, notes);

// job
export const CREATE_JOB_ACTION = 'CREATE_JOB_ACTION';
export const createJob = (data) => action(CREATE_JOB_ACTION, data);

export const CHANGE_SCHEDULE = 'CHANGE_SCHEDULE';
export const changeSchedule = (payload) => action(CHANGE_SCHEDULE, payload);

export const FETCH_JOB_ACTION = 'FETCH_JOB_ACTION';
export const fetchJob = (jobId) => action(FETCH_JOB_ACTION, jobId);

export const FETCH_JOB_CANDIDATES_ACTION = 'FETCH_JOB_CANDIDATES_ACTION';
export const fetchJobCandidates = (payload) => action(FETCH_JOB_CANDIDATES_ACTION, payload);

export const CLEAR_JOB_CANDIDATES_ACTION = 'CLEAR_JOB_CANDIDATES_ACTION';
export const clearJobCandidates = () => action(CLEAR_JOB_CANDIDATES_ACTION);

export const JOB_CANDIDATES_FILTER_CHANGE = 'JOB_CANDIDATES_FILTER_CHANGE';

export const SET_BEST_PICK_CANDIDATES = 'SET_BEST_PICK_CANDIDATES';
export const setBestPickCandidate = (payload) => action(SET_BEST_PICK_CANDIDATES, payload);

export const SHOW_JOB_CANDIDATES_FOR_PAGE = 'SHOW_JOB_CANDIDATES_FOR_PAGE';
export const showJobCandidatesForPage = (page) =>
  action(SHOW_JOB_CANDIDATES_FOR_PAGE, { payload: page });

export const FETCH_CANDIDATE_JOB_OVERTIME_ACTION = 'FETCH_CANDIDATE_JOB_OVERTIME_ACTION';
export const fetchCandidateJobOvertime = (data) =>
  action(FETCH_CANDIDATE_JOB_OVERTIME_ACTION, data);

export const GET_USER_OVERTIME_LIMITS_ACTION = 'GET_USER_OVERTIME_LIMITS_ACTION';
export const getUserOvertimeLimits = () => action(GET_USER_OVERTIME_LIMITS_ACTION);

export const SELECT_JOB_ACTION = 'SELECT_JOB_ACTION';
export const selectJob = (job) => action(SELECT_JOB_ACTION, { payload: job });

export const EDIT_JOB_ACTION = 'EDIT_JOB_ACTION';
export const editJob = (jobId, jobData) => action(EDIT_JOB_ACTION, jobId, jobData);

export const GET_ALL_JOBS_ACTION = 'GET_ALL_JOBS_ACTION';
export const getAllJobs = (data) => action(GET_ALL_JOBS_ACTION, data);

export const GET_JOBS_BY_STATUS_DATE_ACTION = 'GET_JOBS_BY_STATUS_DATE_ACTION';
export const getJobsByStatusOrDate = (status, date) =>
  action(GET_JOBS_BY_STATUS_DATE_ACTION, { status, date });

export const GET_JOBS = 'GET_JOBS';
export const getJobs = ({ limit, page, professionalId, status }) =>
  action(GET_JOBS, {
    limit: limit || 10,
    page: page || 1,
    professionalId,
    status,
  });

export const FETCH_JOBS_WITH_TRANSACTIONS = 'FETCH_JOBS_WITH_TRANSACTIONS';
export const fetchJobsWithTransactions = (data) => action(FETCH_JOBS_WITH_TRANSACTIONS, data);

export const CONFIRM_JOB_ACTION = 'CONFIRM_JOB_ACTION';
export const confirmJob = (jobId, counterOfferId) =>
  action(CONFIRM_JOB_ACTION, jobId, counterOfferId);

export const CANCEL_JOB_ACTION = 'CANCEL_JOB_ACTION';
export const cancelJob = (jobId) => action(CANCEL_JOB_ACTION, jobId);

export const HIDE_SUCCESS_JOB_CANCELATION = 'HIDE_SUCCESS_JOB_CANCELATION';
export const hideSuccessJobCancelation = () => action(HIDE_SUCCESS_JOB_CANCELATION);

export const GET_LAST_POSTED_JOBS = 'GET_LAST_POSTED_JOBS';
export const getLastPostedJobs = () => action(GET_LAST_POSTED_JOBS);

export const REPORT_INCIDENT_ACTION = 'REPORT_INCIDENT_ACTION';
export const reportIncident = (data) => action(REPORT_INCIDENT_ACTION, data);

export const FETCH_CANCELLATION_REASONS = 'FETCH_CANCELLATION_REASONS';
export const fetchCancellationReasons = (jobId) => action(FETCH_CANCELLATION_REASONS, jobId);

export const SUBMIT_CANCELLATION_REASON = 'SUBMIT_CANCELLATION_REASON';
export const submitCancellationReason = (data) => action(SUBMIT_CANCELLATION_REASON, data);

export const UPDATE_JOB_ACTION = 'UPDATE_JOB_ACTION';
export const updateJob = (jobData) => action(UPDATE_JOB_ACTION, jobData);

export const FETCH_OPEN_JOBS_ACTION = 'FETCH_OPEN_JOBS_ACTION';
export const fetchOpenJobs = (jobData) => action(FETCH_OPEN_JOBS_ACTION, jobData);

export const FETCH_SCHEDULED_JOBS_ACTION = 'FETCH_SCHEDULED_JOBS_ACTION';
export const fetchScheduledJobs = (jobData) => action(FETCH_SCHEDULED_JOBS_ACTION, jobData);

export const FETCH_ACTION_REQUIRED_JOBS_ACTION = 'FETCH_ACTION_REQUIRED_JOBS_ACTION';
export const fetchActionRequiredJobs = (jobData) =>
  action(FETCH_ACTION_REQUIRED_JOBS_ACTION, jobData);

export const FETCH_TODAY_JOBS_ACTION = 'FETCH_TODAY_JOBS_ACTION';
export const fetchTodayJobs = (jobData) => action(FETCH_TODAY_JOBS_ACTION, jobData);

export const CLOSE_OVERLAPPING_MODAL = 'CLOSE_OVERLAPPING_MODAL';
export const closeOverlappingModal = (data) => action(CLOSE_OVERLAPPING_MODAL, data);

export const POST_PP_JOB = 'POST_PP_JOB';
export const postPermanentHireJob = (jobData) => action(POST_PP_JOB, jobData);

export const DECLINE_COUNTER_OFFER = 'DECLINE_COUNTER_OFFER';
export const declineCounterOffer = (jobId, counterOfferId) =>
  action(DECLINE_COUNTER_OFFER, jobId, counterOfferId);

export const DECLINE_CANDIDATE = 'DECLINE_CANDIDATE';
export const declineCandidate = (jobId, counterOfferId) =>
  action(DECLINE_CANDIDATE, jobId, counterOfferId);

export const ADJUST_HOURS_ACTION = 'ADJUST_HOURS_ACTION';
export const adjustHours = (jobId, counterOfferId) =>
  action(ADJUST_HOURS_ACTION, jobId, counterOfferId);

export const FETCH_EXPIRED_COUNTER_OFFERS = 'FETCH_EXPIRED_COUNTER_OFFERS';
export const fetchExpiredCounterOffers = (jobId) => action(FETCH_EXPIRED_COUNTER_OFFERS, jobId);

export const FETCH_DASHBOARD_INFO = 'FETCH_DASHBOARD_INFO';
export const fetchDashboardInfo = () => action(FETCH_DASHBOARD_INFO);

export const FETCH_RECEIPTS_REPORT = 'FETCH_RECEIPTS_REPORT';
export const fetchReceiptsCVS = (state) => action(FETCH_RECEIPTS_REPORT, { state });

export const CLEAR_RECEIPTS_REPORT = 'CLEAR_RECEIPTS_REPORT';
export const clearReceiptsReport = () => action(CLEAR_RECEIPTS_REPORT);

export const CLEAR_ALL_RECEIPTS = 'CLEAR_ALL_RECEIPTS';
export const clearAllReceipts = () => action(CLEAR_ALL_RECEIPTS);

export const FETCH_INVOICE_LIST = 'FETCH_INVOICE_LIST';
export const fetchInvoiceList = (state) => action(FETCH_INVOICE_LIST, { state });

export const SHOW_PAYMENT_MISSING_POPUP = 'SHOW_PAYMENT_MISSING_POPUP';
export const showPaymentMissingPopup = (data) => action(SHOW_PAYMENT_MISSING_POPUP, data);

export const HIDE_PAYMENT_MISSING_POPUP = 'HIDE_PAYMENT_MISSING_POPUP';
export const hidePaymentMissingPopup = () => action(HIDE_PAYMENT_MISSING_POPUP);

export const HIDE_SUCCESS_BANK_ADDED_POPUP = 'HIDE_SUCCESS_BANK_ADDED_POPUP';
export const hideSuccessBankAddedPopup = () => action(HIDE_SUCCESS_BANK_ADDED_POPUP);

export const FETCH_PROFESSIONAL_JOBS = 'FETCH_PROFESSIONAL_JOBS';
export const fetchProfessionalJobs = ({ limit, page, professionalId, status }) =>
  action(FETCH_PROFESSIONAL_JOBS, { limit, page, professionalId, status });

export const BULK_CONFIRM_JOBS = 'BULK_CONFIRM_JOBS';
export const bulkConfirmJobs = (jobs) => action(BULK_CONFIRM_JOBS, { payload: jobs });

// multiday
export const SELECT_DAYS_MDB = 'SELECT_DAYS_MDB';
export const selectDaysMDB = (jobDates) => action(SELECT_DAYS_MDB, jobDates);

export const SELECT_DEFAULT_INFO_MDB = 'SELECT_DEFAULT_INFO_MDB';
export const selectDefaultInfoMDB = (jobData) => action(SELECT_DEFAULT_INFO_MDB, jobData);

export const EDIT_JOB_INFO_MDB = 'EDIT_JOB_INFO_MDB';
export const editJobInfoMdb = (jobDate, jobData) => action(EDIT_JOB_INFO_MDB, { jobDate, jobData });

export const DELETE_JOB_FROM_MDB = 'DELETE_JOB_FROM_MDB';
export const deleteJobFromMDB = (jobDate) => action(DELETE_JOB_FROM_MDB, jobDate);

export const MULTIDAY_BOOKING_INVITE_POST = 'MULTIDAY_BOOKING_INVITE_POST';
export const postInviteToMultiDayJob = (data) => action(MULTIDAY_BOOKING_INVITE_POST, data);

// alerts

export const SELECT_CONTENT_TYPE_INBOX = 'SELECT_CONTENT_TYPE_INBOX';
export const selectContentTypeInbox = (payload) => action(SELECT_CONTENT_TYPE_INBOX, payload);

export const GET_ALL_ALERTS_ACTION = 'GET_ALL_ALERTS_ACTION';
export const getAlerts = (state) => action(GET_ALL_ALERTS_ACTION, { state });

export const DELETE_ALL_ALERTS_ACTION = 'DELETE_ALL_ALERTS_ACTION';
export const deleteAllAlerts = () => action(DELETE_ALL_ALERTS_ACTION);

export const DELETE_ONE_ALERT_ACTION = 'DELETE_ONE_ALERT_ACTION';
export const deleteOneAlert = (alertId) => action(DELETE_ONE_ALERT_ACTION, alertId);

export const SET_TOTAL_REMINDERS_ACTION = 'SET_TOTAL_REMINDERS_ACTION';
export const setTotalReminders = (total) => action(SET_TOTAL_REMINDERS_ACTION, { payload: total });

// messages

export const GET_USER_MESSAGES = 'GET_USER_MESSAGES';
export const getUserMessages = (state) => action(GET_USER_MESSAGES, { state });

export const GET_MESSAGES_BY_JOB_ID = 'GET_MESSAGES_BY_JOB_ID';
export const getMessagesByJobId = (jobId) => action(GET_MESSAGES_BY_JOB_ID, jobId);

export const POST_NEW_MESSAGE = 'POST_NEW_MESSAGE';
export const postMessage = (data) => action(POST_NEW_MESSAGE, data);

export const REQUEST_ADMIN = 'REQUEST_ADMIN';
export const requestAdminMessage = (jobId) => action(REQUEST_ADMIN, jobId);

// receipts

export const FETCH_RECEIPT_LIST = 'FETCH_RECEIPT_LIST';
export const fetchReceiptList = (state) => action(FETCH_RECEIPT_LIST, { state });

export const UPDATE_RECEIPT_DISPLAY_MODE = 'UPDATE_RECEIPT_DISPLAY_MODE';
export const updateReceiptDisplayMode = (payload) =>
  action(UPDATE_RECEIPT_DISPLAY_MODE, { payload });

// referrals

export const SAVE_GIFT_EMAIL = 'SAVE_GIFT_EMAIL';
export const saveGiftEmail = (email) => action(SAVE_GIFT_EMAIL, email);

export const CREATE_REFERRAL_LINK = 'CREATE_REFERRAL_LINK';
export const createReferralLink = () => action(CREATE_REFERRAL_LINK);

// adjustments

export const FETCH_JOBS_WITH_ADJUSTMENTS = 'FETCH_JOBS_WITH_ADJUSTMENTS';
export const fetchJobsWithAdjustmts = () => action(FETCH_JOBS_WITH_ADJUSTMENTS);

export const CHANGE_STATUS_ADJUSTMENT = 'CHANGE_STATUS_ADJUSTMENT';
export const changeStatusOfAdjustments = (jobId, adjustmentId, status, disputeReason) =>
  action(CHANGE_STATUS_ADJUSTMENT, jobId, adjustmentId, status, disputeReason);

// notifications

export const FETCH_NOTIFICATION_SETTINGS = 'FETCH_NOTIFICATION_SETTINGS';
export const fetchNotificationSettings = () => action(FETCH_NOTIFICATION_SETTINGS);

export const UPDATE_NOTIFICATION_SETTINGS = 'UPDATE_NOTIFICATION_SETTINGS';
export const updateNotificationSettings = (payload) =>
  action(UPDATE_NOTIFICATION_SETTINGS, payload);

// locum tenens dentist
export const CREATE_LOCUM_TENENS_DENTIST_ACTION = 'CREATE_LOCUM_DENTIST';
export const createLocumTenensRequest = (text) =>
  action(CREATE_LOCUM_TENENS_DENTIST_ACTION, { text });

// favorites professionals list
export const FETCH_FAVORITE_PROFESSIONALS = 'FETCH_FAVORITE_PROFESSIONALS';
export const fetchFavoriteProfessionals = (jobId) =>
  action(FETCH_FAVORITE_PROFESSIONALS, { jobId });

export const REMOVE_FAVORITE_PROFESSIONAL = 'REMOVE_FAVORITE_PROFESSIONAL';
export const removeFavoriteProfessional = (userId) => action(REMOVE_FAVORITE_PROFESSIONAL, userId);

export const ADD_FAVORITE_PROFESSIONAL = 'ADD_FAVORITE_PROFESSIONAL';
export const addFavoriteProfessional = (userId) => action(ADD_FAVORITE_PROFESSIONAL, { userId });

export const INVITE_FAVORITE_TO_JOB = 'INVITE_FAVORITE_TO_JOB';
export const inviteFavoriteToJob = (jobId, userIds) =>
  action(INVITE_FAVORITE_TO_JOB, { jobId, userIds });

export const INVITE_PROFESSIONALS_TO_JOBS = 'INVITE_PROFESSIONALS_TO_JOBS';
export const inviteProfessionalsToJobs = ({ jobIds, professionalIds }) =>
  action(INVITE_PROFESSIONALS_TO_JOBS, { jobIds, professionalIds });

// Recent Professionals
export const FETCH_RECENT_PROFESSIONALS = 'FETCH_RECENT_PROFESSIONALS';
export const fetchRecentProfessionals = (params) => action(FETCH_RECENT_PROFESSIONALS, params);

export const BLOCK_RECENT_PROFESSIONAL = 'BLOCK_RECENT_PROFESSIONAL';
export const blockRecentProfessional = (data) => action(BLOCK_RECENT_PROFESSIONAL, data);

export const FETCH_OPEN_JOBS_FOR_RECENT_PROFESSIONAL_ACTION =
  'FETCH_OPEN_JOBS_FOR_RECENT_PROFESSIONAL_ACTION';
export const fetchOpenJobsForRecentProfessional = (data) =>
  action(FETCH_OPEN_JOBS_FOR_RECENT_PROFESSIONAL_ACTION, { payload: data });

// Highly-Rated Professionals
export const FETCH_HIGHLY_RATED_PROFESSIONALS_ACTION = 'FETCH_HIGHLY_RATED_PROFESSIONALS_ACTION';
export const fetchHighlyRatedProfessionals = (params) =>
  action(FETCH_HIGHLY_RATED_PROFESSIONALS_ACTION, { payload: params });

// Invites
export const FETCH_OFFICE_JOB_INVITES_ACTION = 'FETCH_OFFICE_JOB_INVITES_ACTION';
export const fetchOfficeJobInvites = (params) =>
  action(FETCH_OFFICE_JOB_INVITES_ACTION, { payload: params });

// partnership code

export const FETCH_PARTNERSHIP_ORGANIZATIONS = 'FETCH_PARTNERSHIP_ORGANIZATIONS';
export const fetchPartnershipOrganizations = () => action(FETCH_PARTNERSHIP_ORGANIZATIONS);

export const VERIFY_PARTNERSHIP_CODE = 'VERIFY_PARTNERSHIP_CODE';
export const verifyPartnershipCode = (data) => action(VERIFY_PARTNERSHIP_CODE, data);

export const HIDE_PARTNERSHIP_CODE_SUCCESS_POPUP = 'HIDE_PARTNERSHIP_CODE_SUCCESS_POPUP';
export const hidePartnershipCodeSuccessPopup = () => action(HIDE_PARTNERSHIP_CODE_SUCCESS_POPUP);

// metadata

export const FETCH_METADATA = 'FETCH_METADATA';
export const fetchMetadata = () => action(FETCH_METADATA);

// worker type
export const SAVE_WORKER_CLASSIFICATION = 'SAVE_WORKER_CLASSIFICATION';
export const saveWorkerClassification = (payload) =>
  action(SAVE_WORKER_CLASSIFICATION, { payload });

export const GET_STATE_WORKER_CLASSIFICATION = 'GET_STATE_WORKER_CLASSIFICATION';
export const getStateWorkerClassification = () => action(GET_STATE_WORKER_CLASSIFICATION);

export const GET_WORKER_CLASSIFICATION_MANAGEMENT = 'GET_WORKER_CLASSIFICATION_MANAGEMENT';
export const getWorkerClassificationManagement = () => action(GET_WORKER_CLASSIFICATION_MANAGEMENT);

export const SAVE_CHILD_WORKER_CLASSIFICATION = 'SAVE_CHILD_WORKER_CLASSIFICATION';
export const saveChildOfficeWorkerClassification = (data) =>
  action(SAVE_CHILD_WORKER_CLASSIFICATION, data);

export const SAVE_STATUS_CHILD_WORKER_CLASSIFICATION = 'SAVE_STATUS_CHILD_WORKER_CLASSIFICATION';
export const saveStatusChildOfficeWorkerClassification = (data) =>
  action(SAVE_STATUS_CHILD_WORKER_CLASSIFICATION, data);

export const HIDE_SUCCESS_WORKER_CLASSIFICATION_POPUP = 'HIDE_SUCCESS_WORKER_CLASSIFICATION_POPUP';
export const hideSuccessWorkerClassificationPopup = () =>
  action(HIDE_SUCCESS_WORKER_CLASSIFICATION_POPUP);

export const SHOW_CONNECTIONS_HUB_POPUP = 'SHOW_CONNECTIONS_HUB_POPUP';
export const showConnectionsHubPopup = () => action(SHOW_CONNECTIONS_HUB_POPUP);

export const HIDE_CONNECTIONS_HUB_POPUP = 'HIDE_CONNECTIONS_HUB_POPUP';
export const hideConnectionsHubPopup = () => action(HIDE_CONNECTIONS_HUB_POPUP);

export const SHOW_CONFIRM_CANDIDATE_ANOTHER_JOB_POPUP = 'SHOW_CONFIRM_CANDIDATE_ANOTHER_JOB_POPUP';
export const showConfirmCandidateAnotherJobPopup = () =>
  action(SHOW_CONFIRM_CANDIDATE_ANOTHER_JOB_POPUP);

export const HIDE_CONFIRM_CANDIDATE_ANOTHER_JOB_POPUP = 'HIDE_CONFIRM_CANDIDATE_ANOTHER_JOB_POPUP';
export const hideConfirmCandidateAnotherJobPopup = () =>
  action(HIDE_CONFIRM_CANDIDATE_ANOTHER_JOB_POPUP);

export const CHANGE_HUB_FILTER = 'CHANGE_HUB_FILTER';
export const changeHubFilter = (filter) => action('CHANGE_HUB_FILTER', { payload: filter });

export const CLEAR_MULTIDAY_FORM = 'CLEAR_MULTIDAY_FORM';
export const clearMultidayForm = () => action(CLEAR_MULTIDAY_FORM);

export const GET_OFFICE_REVIEW_INFO = 'GET_OFFICE_REVIEW_INFO';
export const getOfficeReviewInfo = ({ userId }) =>
  action(GET_OFFICE_REVIEW_INFO, { payload: { userId } });

export const SHOW_REPORT_INCIDENT_POPUP = 'SHOW_REPORT_INCIDENT_POPUP';
export const showReportIncidentPopup = (payload) => action(SHOW_REPORT_INCIDENT_POPUP, { payload });

export const CANCEL_CONFIRM_REPORT_INCIDENT_POPUP = 'CANCEL_CONFIRM_REPORT_INCIDENT_POPUP';
export const cancelConfirmReportIncidentPopup = () => action(CANCEL_CONFIRM_REPORT_INCIDENT_POPUP);

export const SHOW_CONFIRM_REPORT_INCIDENT_POPUP = 'SHOW_CONFIRM_REPORT_INCIDENT_POPUP';
export const showConfirmReportIncidentPopup = ({ incidentType }) =>
  action(SHOW_CONFIRM_REPORT_INCIDENT_POPUP, { payload: { incidentType } });

export const SHOW_REPOST_NCNS_POPUP = 'SHOW_REPOST_NCNS_POPUP';
export const showRepostNcnsPopup = () => action(SHOW_REPOST_NCNS_POPUP);

export const SHOW_INCIDENT_REPORTED_POPUP = 'SHOW_INCIDENT_REPORTED_POPUP';
export const showIncidentReportedPopup = () => action(SHOW_INCIDENT_REPORTED_POPUP);

export const SHOW_SHIFT_REPOSTED_POPUP = 'SHOW_SHIFT_REPOSTED_POPUP';
export const showShiftRepostedPopup = () => action(SHOW_SHIFT_REPOSTED_POPUP);

export const RESET_INCIDENT = 'RESET_INCIDENT';
export const resetIncident = () => action(RESET_INCIDENT);

export const CLEAR_INCIDENT_POPUPS = 'CLEAR_INCIDENT_POPUPS';
export const clearIncidentPopups = () => action(CLEAR_INCIDENT_POPUPS);

export const GET_REFERRAL_LIST_ACTION = 'GET_REFERRAL_LIST_ACTION';
export const getReferralList = () => action(GET_REFERRAL_LIST_ACTION);

export const GET_REFERRAL_SUMMARY_ACTION = 'GET_REFERRAL_SUMMARY_ACTION';
export const getReferralSummary = () => action(GET_REFERRAL_SUMMARY_ACTION);

export const GET_REFERRAL_PAYOUTS_ACTION = 'GET_REFERRAL_PAYOUTS_ACTION';
export const getReferralPayouts = () => action(GET_REFERRAL_PAYOUTS_ACTION);

export const CONFIRM_REFERRAL_PAYOUT_ACTION = 'CONFIRM_REFERRAL_PAYOUT_ACTION';
export const confirmReferralPayout = (referralId) =>
  action(CONFIRM_REFERRAL_PAYOUT_ACTION, { referralId });

export const HIDE_REFERRAL_PAYOUT_POPUP = 'HIDE_REFERRAL_PAYOUT_POPUP';
export const hideReferralPayoutPopup = () => action(HIDE_REFERRAL_PAYOUT_POPUP);
